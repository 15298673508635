import { appReleaseFeed } from '../constants/urls';

/**
 * Utility to parse the RSS channel for MeshOS updates to get the latest
 * release's download URL
 * @returns promise for the download URL
 */
export async function parseReleaseFeed(): Promise<string> {

  let url = '';

  // fetch the feed
  const response = await fetch(appReleaseFeed);

  // checks for HTTP 200-299
  if (!response.ok) {
    throw new Error(`Unable to load update feed, got HTTP response code ${response.status}`);
  }

  // convert to text
  const data = await response.text();

  // parse the XML
  const parser = new window.DOMParser();
  const document = parser.parseFromString(data, "text/xml");

  //let versionString = '';
  //let releaseNotesLink = '';

  // get the first <item> in the <channel>, that will be the newest release
  const item = document.documentElement.querySelector('channel item');
  if (item) {
    //console.log(item);

    // get the human-readable version string
    // const titleElt = item.querySelector('title');
    // if (titleElt) {
    //   versionString = titleElt.innerHTML;
    // }

    // get the link to the release notes
    // const releaseNotesElt = item.querySelector('releaseNotesLink');
    // if (releaseNotesElt) {
    //   releaseNotesLink = releaseNotesElt.innerHTML;
    // }

    // get the download URL
    const enclosureElt = item.querySelector('enclosure');
    if (enclosureElt) {
      url = enclosureElt.getAttribute('url') ?? '';
      //console.log('got url:', url);
    }
  }

  return url;
}
