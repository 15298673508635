import React from 'react';
import { Helmet } from 'react-helmet-async';

//import YouTube from 'react-lite-youtube-embed';
//import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';

import Type from '../../components/Type';
import SignupButton from '../../components/SignupButton';

//import emailIMG from '../../assets/images/composing-email.jpg';


export function Home() {
  return (
    <div>
      <Helmet>
        <title>MeshOS: Next-Generation Working Environment</title>
      </Helmet>
      <section className="container m-auto px-4 sm:px-6 pb-6 md:-mt-6 border-box text-center">
        <Type size="title" className="md:mt-20 sm:mb-10 text-center">
          We&apos;re building a next-generation working environment.
        </Type>
        <div className='md:max-w-xl lg:max-w-4xl md:mx-auto'>
          <Type className='text-xl sm:max-w-xl sm:mx-auto mb-6'>
            We all have more information than we can manage, in cluttered desktops
            and many browser tabs. Switching context is not easy.
          </Type>
          <Type className='text-xl sm:max-w-xl sm:mx-auto mb-6'>
            MeshOS brings a new approach. The information and tools you need for each
            topic now live in persistent collaborative workspaces. This new model
            unlocks many benefits.
          </Type>
          <Type className='text-xl sm:max-w-xl sm:mx-auto mb-6'>
            Our beta requires a Mac and is free.
          </Type>
          <SignupButton
            className='mt-4'
            size='hero'
            showIcon={false}
            label='Join the Beta'
          />
          <video
            preload="auto"
            poster="https://us-west-1-meshos-videos.s3.us-west-1.amazonaws.com/videos/IntroductionToMesh.png"
            className='max-w-[500px] sm:max-w-xl md:max-w-xl lg:max-w-4xl md:mx-auto my-6'
            controls
          >
            <source
              src="https://us-west-1-meshos-videos.s3.us-west-1.amazonaws.com/videos/IntroductionToMesh.mp4"
              type="video/mp4"
            />
          </video>
          {/* <YouTube
            id="JiufCDVU4dM"
            title="How I Make My Videos From Start to Finish"
            adNetwork={true}
            noCookie={false}
          /> */}
          {/* <Type className='text-lg text-justify my-8'>
            <img className="float-left m-2" src={emailIMG} alt="composing email" />
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
            tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
            veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
            commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
            velit esse cillum dolore eu fugiat nulla pariatur.<br />
            <br />
            Facilisis magna etiam tempor orci eu lobortis elementum. Ultricies leo
            integer malesuada nunc vel risus. Porttitor lacus luctus accumsan tortor
            posuere ac. Mi ipsum faucibus vitae aliquet. Aliquet sagittis id consectetur
            purus. Ultricies leo integer malesuada nunc vel risus. Turpis cursus in hac
            habitasse platea dictumst quisque sagittis purus.
          </Type> */}
        </div>
      </section>
    </div>
  );
}

export default Home;
