/**
 * This module was automatically generated by `ts-interface-builder`
 */
import * as t from "ts-interface-checker";
// tslint:disable:object-literal-key-quotes

export const UserShallow = t.iface([], {
  "id": "string",
  "email": t.opt("string"),
  "color": "string",
  "displayName": "string",
  "image": t.opt("string"),
  "emailHash": t.opt("string"),
  "initials": "string",
  "jsonType": "string",
  "userSince": t.opt("number"),
  "publisherProfile": t.opt(t.iface([], {
    "slug": "string",
    "image": t.opt("string"),
    "color": "string",
    "bio": t.opt("string"),
    "supportEmail": t.opt("string"),
    "alias": t.opt("string"),
  })),
});

export const WorkspaceShallow = t.iface([], {
  "id": "string",
  "slug": "string",
  "approved": t.opt("boolean"),
  "unlisted": t.opt("boolean"),
  "category": "CategoryMinimal",
  "banner": "string",
  "images": t.array("string"),
  "displayName": "string",
  "description": "string",
  "website": t.opt("string"),
  "bannerColor": t.opt("string"),
  "textColor": t.opt("string"),
  "bgColor": t.opt("string"),
  "tags": t.opt(t.union(t.array("string"), "string")),
});

export const CategoryShallow = t.iface(["CategoryMinimal"], {
  "banner": "string",
});

export const CategoryMinimal = t.iface([], {
  "slug": "string",
  "displayName": "string",
});

export const ReviewShallow = t.iface([], {
  "id": "string",
  "rating": "number",
  "body": "string",
  "posted": "number",
});

export const TeamShallow = t.iface([], {
  "id": "string",
  "displayName": "string",
  "image": "string",
});

const exportedTypeSuite: t.ITypeSuite = {
  UserShallow,
  WorkspaceShallow,
  CategoryShallow,
  CategoryMinimal,
  ReviewShallow,
  TeamShallow,
};
export default exportedTypeSuite;
