import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket as RocketIcon } from '@fortawesome/free-solid-svg-icons';

import propOrDefault from '../../utils/propOrDefault';

import Button from '../Button';

export interface SignupButtonProps {
  size?: 'hero' | 'large' | 'medium' | 'small';
  color?: 'primary' | 'secondary' | 'light' | 'dark';
  className?: string;
  /** Should the button display full width? Default: false */
  full?: boolean;
  /** Custom button label. Default: 'Join the Beta' */
  label?: string;
  /** Should the button display an icon? Default: true */
  showIcon?: boolean;
}

export function SignupButton(props: SignupButtonProps) {
  const size = propOrDefault(props.size, 'medium');
  const color = propOrDefault(props.color, 'primary');
  const label = propOrDefault(props.label, 'Join the Beta');
  const showIcon = propOrDefault(props.showIcon, true);
  const full = propOrDefault(props.full, false);
  const navigate = useNavigate();

  return (
    <Button
      onClick={() => { navigate("/signup"); }}
      color={color}
      size={size}
      full={full}
      className={props.className}
    >
      {label}
      {showIcon && <FontAwesomeIcon className="ml-3" icon={RocketIcon} />}
    </Button>
  );
}

export default SignupButton;
