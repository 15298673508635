// import isEmpty from 'lodash.isempty';

import { serverTimestamp } from 'firebase/database';
import {
  writeToRef,
  stringFlattenKeys,
  JsonObj,
} from './helpers';
import { resizeAndUploadImage } from './storage';

import { User, Workspace } from '../constants/database';
import initials from '../utils/initials';

/*
USER DENORMALIZATION DIAGRAM:

spaces_public
  WORKSPACE ID (could be > 1 - iterate over User's spaces)
    publisher: User (with id!)
users_public
  UUID: User
marketplace_urls
  publishers
    SLUG: UUID
*/

export async function updateAuthProfile(
  original: User,
  modifications: Record<string, unknown>
) {
  if (modifications.publisherProfile) {
    let profile = modifications.publisherProfile as Record<string, unknown>;
    if (profile.spaces === undefined) {
      delete profile.spaces;
    }
  }

  // process modifications
  let flattened = stringFlattenKeys(modifications as JsonObj);

  let full: { [key: string]: any } = {};
  let shallow: { [key: string]: any } = {};

  flattened.email = ''; // For now

  for (let key in flattened) {
    if (flattened[key] !== '') {
      if (key === 'image' || key === 'publisherProfile/image') {
        const imageRef =
          (key === 'image' ? 'profilePhotos/' : 'publisherPhotos/') +
          original.id;
        const imageURL =
          (await resizeAndUploadImage(imageRef, flattened[key] as string)) || null;
        flattened[key] = imageURL;
      } else if (key === 'publisherProfile/slug') {
        flattened[key] = (flattened[key] as string).toLowerCase();
      } else if (key === 'displayName') {
        full.initials = initials(flattened.displayName as string);
      }
      full[key] = flattened[key];
      if (!key.startsWith('spaces')) {
        shallow[key] = flattened[key];
      }
    }
  }

  // compile writes
  let writes: JsonObj = {
    users_public: { [original.id]: full }
  };

  if (
    flattened['publisherProfile/slug'] &&
    (!original.publisherProfile ||
      !original.publisherProfile.slug ||
      flattened['publisherProfile/slug'] !==
        original.publisherProfile.slug)
  ) {
    writes.marketplace_urls = {
      publishers: {
        [full['publisherProfile/slug']]: original.id
      }
    };
    if (original.publisherProfile && original.publisherProfile.slug) {
      let publishers = writes.marketplace_urls.publishers as JsonObj;
      publishers[original.publisherProfile.slug] = null;
    }
  }

  if (original.publisherProfile && original.publisherProfile.spaces) {
    writes.spaces_public = {};
    writes.marketplace_categories = {};
    for (let space of Object.values(original.publisherProfile.spaces)) {
      writes.spaces_public[space.id] = {
        publisher: shallow
      };
      writes.marketplace_categories[space.category.slug] = {
        spaces: { [space.id]: { publisher: shallow } }
      };
    }
  }

  writes = stringFlattenKeys(writes);

  await writeToRef('', writes);
  return 'Profile updated succesfully!';
}

/*
WORKSPACE DENORMALIZATION DIAGRAM:

spaces_public
  UUID: Workspace
users_public
  PUBLISHER ID
    publisherProfile
      spaces
        UUID: Workspace
marketplace_urls
  products
    SLUG: UUID
marketplace_categories
  CATEGORY
    spaces
      UUID: Workspace
marketplace_featured
  FEATURE WEEK (could be > 1)
    spaces
      UUID: Workspace
user_workspaces ? - would have to update all trials, TBD
*/

export async function updateWorkspace(
  original: Workspace,
  modifications: Record<string,unknown>
) {
  // process modifications
  let flattened = stringFlattenKeys(modifications as JsonObj);

  let full: { [key: string]: any } = {};
  let withPublisher: { [key: string]: any } = {};
  let withCategory: { [key: string]: any } = {};

  for (let key in flattened) {
    if (flattened[key] !== '') {
      if (key === 'banner' || key.startsWith('images/')) {
        const imageRef = 'productPhotos/' + original.id + '/' + key;
        const imageURL =
          (await resizeAndUploadImage(
            imageRef,
            flattened[key] as string,
            key === 'banner' ? 1000 : 2160,
            key === 'banner' ? 500 : 1200
          )) || null;
        flattened[key] = imageURL;
      } else if (key === 'slug') {
        flattened.slug = (flattened.slug as string).toLowerCase();
      } else if (key === 'tags') {
        flattened.tags = (flattened.tags as string)
          .split(',')
          .map((tag: string) => tag.trim());
      }
      full[key] = flattened[key];
      if (!key.startsWith('category')) {
        withPublisher[key] = flattened[key];
      }
      if (!key.startsWith('publisher')) {
        withCategory[key] = flattened[key];
      }
    }
  }

  // compile writes
  let writes: JsonObj = {
    spaces_public: { [original.id]: full },
    users_public: {
      [original.publisher.id]: {
        publisherProfile: { spaces: { [original.id]: withCategory } }
      }
    }
  };

  if (
    !original.category ||
    !original.category.slug ||
    full['category/slug'] !== original.category.slug
  ) {
    writes.marketplace_categories = {
      [full['category/slug']]: { spaces: { [original.id]: withPublisher } }
    };
    if (original.category && original.category.slug) {
      writes.marketplace_categories[original.category.slug] = {
        spaces: { [original.id]: null }
      };
    }
  } else {
    writes.marketplace_categories = {
      [original.category.slug]: { spaces: { [original.id]: withPublisher } }
    };
  }

  if (!original.slug || full.slug !== original.slug) {
    writes.marketplace_urls = {
      products: {
        [full.slug]: original.id
      }
    };
    if (original.slug) {
      if (writes.marketplace_urls.products) {
        let products = writes.marketplace_urls.products as JsonObj;
        products[original.slug] = null;
      }
    }
  }

  if (original.featured) {
    writes.marketplace_featured = {};
    for (let week of Object.keys(original.featured)) {
      writes.marketplace_featured[week] = {
        spaces: { [original.id]: withPublisher }
      };
    }
  }

  writes = stringFlattenKeys(writes);

  await writeToRef('', writes);
  return full.slug || original.slug;
}

export function publishWorkspace(
  id: string,
  publisher: User,
  values: { [key: string]: any }
) {
  let original = { id, publisher };
  let publisherShallow = { ...publisher };
  if (publisherShallow.publisherProfile) {
    delete publisherShallow.publisherProfile.spaces;
  }
  values.publisher = publisherShallow;
  values.id = id;
  return updateWorkspace(original as Workspace, values);
}

export function startTrial(user: User, workspace: Workspace) {
  return writeToRef(
    'users_workspaces/',
    {
      [user.id]: {
        trials: {
          [workspace.id]: {
            originalID: workspace.id,
            trialStart: serverTimestamp() as JsonObj,
            trialDuration: 1000 * 60 * 60 * 24 * 7,
            displayName: workspace.displayName
          }
        }
      }
    },
    true
  );
}
