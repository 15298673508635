import {
  faInfoCircle,
  faExclamationCircle,
  faCheckCircle,
  faExclamationTriangle,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';

// Note: the commented strings are important, they cause Tailwind to include those
//       styles which would otherwise be missed because we created them through
//       string manipulation

const statusColors: Record<string, string> = {
  none: 'gray-200',       // border-gray-200    text-gray-200
  info: 'blue-500',       // border-blue-500    text-blue-500
  warning: 'orange-500',  // border-orange-500  text-orange-500
  success: 'green-500',   // border-green-500   text-green-500
  danger: 'red-500',      // border-red-500     text-red-500
};
export default statusColors;

export const lightColors: Record<string, string> = {
  none: 'gray-100',       // bg-gray-100
  info: 'blue-200',       // bg-blue-200
  warning: 'orange-200',  // bg-orange-200
  success: 'green-200',   // bg-green-200
  danger: 'red-200',      // bg-red-200
};

export const darkColors: Record<string, string> = {
  none: 'gray-800',       // text-gray-800
  info: 'blue-800',       // text-blue-800
  warning: 'orange-800',  // text-orange-800
  success: 'green-800',   // text-green-800
  danger: 'red-800',      // text-red-800
};

export const icons: Record<string, IconDefinition> = {
  none: faInfoCircle,
  info: faInfoCircle,
  warning: faExclamationCircle,
  success: faCheckCircle,
  danger: faExclamationTriangle,
};

