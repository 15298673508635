import React from 'react';

import Type from '../Type';
import Button from '../Button';

export interface HeroProps {
  title: string;
  subtitle: string;
  imageURL: string;
  buttonText?: string;
  buttonURL?: string;
  id?: string;
}

export function Hero(props: HeroProps) {
  return (
    <section id={props.id || 'hero'}>
      <div className="container p-6 flex flex-col-reverse md:flex-row m-auto border-box">
        <div className="md:w-2/3 pt-8 pb-8 pr-8">
          <Type size="title">{props.title}</Type>
          <Type size="subtitle">{props.subtitle}</Type>
          {props.buttonText ? (
            <Button size="hero">{props.buttonText}</Button>
          ) : null}
        </div>
        <div className="w-full md:w-1/3 flex items-center justify-center">
          <img
            alt=""
            className="inline-block w-full flex-1"
            src={props.imageURL}
            id={props.id ? props.id + '-image' : 'hero-image'}
          />
        </div>
      </div>
      <div className="h-10 w-full bg-gray-100 -mt-10" />
    </section>
  );
}

export default Hero;
