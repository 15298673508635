// react
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// form handling
import { Formik, Form, FormikState } from 'formik';
import * as Yup from 'yup';

// custom components
import ActivityIndicator from "../ActivityIndicator";
import Alert from "../Alert";
import Button from "../Button";
import InputCheckbox from "../InputCheckbox";
import InputText from '../InputText';
import Modal from "../Modal";
import Type from "../Type";

// other utilities
import { openerURL } from '../../constants/urls';
import statusCode from '../../constants/statusCode';
import statusColors from '../../constants/statusColors';
import { supportEmail } from '../../constants/urls';
import {
  CreateUserError,
  CreateUserErrorActionCode,
  createFirebaseUser,
  sendVerifyEmail,
  NewUserRecord,
} from '../../api/helpers';


interface SignUpFormProps {
  refCode?: string;
}

interface SignupFormValues {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  acceptedTerms: boolean;
}

const SignUpForm: React.FC<SignUpFormProps> = ({ refCode }) => {
  const errClass = 'text-' + statusColors[statusCode[statusCode.danger]];

  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = useState(false);
  const [creationError, setCreationError] = useState<unknown>(null);

  // error conditions that we show to the user
  let weakPassword = false;
  let accountAlreadyExists = false;

  // something went wrong when creating the Firebase account
  if (creationError) {
    if (creationError instanceof CreateUserError) {
      switch(creationError.code) {
      case CreateUserErrorActionCode.WeakPassword:
        weakPassword = true;
        break;
      case CreateUserErrorActionCode.AccountAlreadyExists:
        accountAlreadyExists = true;
        break;
      }
    } else {
      // unexpected error
      console.error('failed to create Firebase user:', creationError);
      return (
        <Alert
          title="Something went wrong!"
          status={statusCode.danger}
          message={`Try your request again, or contact ${supportEmail}`}
          closeable={false}
        />
      );
    }
  }

  return (
    <>
      <Modal open={isProcessing}>
        <Type size="subtitle">
          Creating account...
          <ActivityIndicator className="inline" size="large" />
        </Type>
      </Modal>
      <Formik<SignupFormValues>
        initialValues={{
          firstname: '',
          lastname: '',
          email: '',
          password: '',
          acceptedTerms: false,
        }}
        validationSchema={Yup.object({
          firstname: Yup.string()
            .max(35, 'Must be 35 characters or less')
            .required('Required'),
          lastname: Yup.string()
            .max(35, 'Must be 35 characters or less')
            .required('Required'),
          email: Yup.string()
            .email('Invalid email address.')
            .required('Required.'),
          password: Yup.string()
            .min(6, 'Must be at least 6 characters long.')
            .required('Required'),
          acceptedTerms: Yup.boolean()
            .required('Required.')
            .oneOf([true], 'You must accept the terms and conditions to create an account.'),
        })}
        onSubmit={async (userData: SignupFormValues) => {
          setIsProcessing(true);
          try {
            const newUser: NewUserRecord = {
              firstname: userData.firstname,
              lastname: userData.lastname,
              email: userData.email,
            };
            if (refCode) {
              newUser.referralCode = refCode;
            }
            // create the new Firebase account
            const _uid = await createFirebaseUser(newUser, userData.password);
            //console.log(`new Firebase uid is ${_uid}`);
            await sendVerifyEmail();
            //console.log('verification email sent, redirecting');
            navigate("/verifyemail");
          } catch (e) {
            console.error('failed to create user: ', e);
            setCreationError(e);
            setIsProcessing(false);
          }
        }}
      >
        {({ isSubmitting, errors, touched }: FormikState<SignupFormValues>) => {
          if (weakPassword) {
            errors.password = "This password is too weak.";
            touched.password = true;
          }
          if (accountAlreadyExists) {
            errors.email = "An account with this email already exists.";
            touched.email = true;
          }
          return (
            <Form className="flex flex-col gap-y-2">
              <Type className='text-center pb-4'>
                Already have an account?
                <a
                  className="ml-2 text-blue-700 hover:underline visited:text-blue-700"
                  href={openerURL}
                  target='_blank' rel='noreferrer'
                >
                  Launch MeshOS
                </a>
              </Type>
              <InputText
                label='First Name'
                name='firstname'
                placeholder='Jack'
                size='small'
                note={errors.firstname && touched.firstname ? errors.firstname : undefined}
                status={errors.firstname && touched.firstname ? statusCode.danger : statusCode.none}
                noComplete
                field
              />
              <InputText
                label='Last Name'
                name='lastname'
                placeholder='Sparrow'
                size='small'
                note={errors.lastname && touched.lastname ? errors.lastname : undefined}
                status={errors.lastname && touched.lastname ? statusCode.danger : statusCode.none}
                noComplete
                field
              />
              <InputText
                label='Email'
                name='email'
                type='email'
                placeholder='rumlover@pirate.net'
                size='small'
                note={errors.email && touched.email ? errors.email : undefined}
                status={errors.email && touched.email ? statusCode.danger : statusCode.none}
                noComplete
                field
              />
              <InputText
                label='Password'
                name='password'
                type='password'
                placeholder=''
                size='small'
                note={errors.password && touched.password ? errors.password : undefined}
                status={errors.password && touched.password ? statusCode.danger : statusCode.none}
                noComplete
                field
              />
              <div className="mb-4">
                <InputCheckbox
                  name="acceptedTerms"
                  size='small'
                  field
                />
                <Type inline>
                  I have read and agree to the
                  <a
                    className="ml-2 text-blue-700 hover:underline visited:text-blue-700"
                    href="/terms"
                    target="_blank" rel="noreferrer"
                  >
                    Terms of Service
                  </a>
                </Type>
                {errors.acceptedTerms && touched.acceptedTerms &&
                  <Type
                    className={errClass}
                    size="subtext"
                    style={{ marginTop: 0 }}
                  >
                    {errors.acceptedTerms}
                  </Type>
                }
              </div>
              <div className="flex justify-center">
                <Button type="submit" disabled={isSubmitting}>
                  Create Account
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
export default SignUpForm;
