import { updateAuthProfile } from '../api';
import { toast } from 'react-toastify';

import { User } from '../constants/database';

const updateProfile = async (
  vals: Record<string, unknown>,
  user: User,
  setUpdateLoading: (val: boolean) => void
) => {

  //console.log('proceeding');
  setUpdateLoading(true);
  try {
    await updateAuthProfile(user, vals);
  } catch(err) {
    toast.warn('Uh oh, your profile changes failed to save.');
    console.error(err);
    setUpdateLoading(false);
    throw err;
  }
  toast.success('Your profile changes have been saved!');
  setUpdateLoading(false);
};

export default updateProfile;
