import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import Alert from '../../components/Alert';
import Type from '../../components/Type';
import Button from '../../components/Button';
import ActivityIndicator from '../../components/ActivityIndicator';

import downloadIMG from '../../assets/images/download.svg';
import downloadMobileIMG from '../../assets/images/download_mobile.svg';
import { useLatestReleaseURL } from '../../api/hooks';
import statusCode from '../../constants/statusCode';
import { supportEmail } from '../../constants/urls';


export const ClientDownload: React.FC = () => {
  const [downloadURL, isLoading, loadingErrMessage] = useLatestReleaseURL();
  const { newUser } = useParams();

  const title = (
    <Helmet>
      <title>MeshOS: Download App</title>
    </Helmet>
  );

  // check for errors loading the download URL
  if (loadingErrMessage) {
    console.error(loadingErrMessage);
    return (
      <>
        {title}
        <div className="container sm:py-32 m-auto border-box max-w-3xl">
          <Alert
            title="Something went wrong!"
            status={statusCode.danger}
            message={`Unable to determine latest download link. Please contact ${supportEmail}`}
            closeable={false}
          />
        </div>
      </>
    );
  }

  // normal response, fancy download link
  return (
    <>
      {title}
      <div className="container sm:p-32 m-auto border-box max-w-3xl">
        {/* {newUser &&
          <Alert
            title="Account Created!"
            status={statusCode.info}
            message="Download the app to get started."
          />
        } */}
        <div className="bg-white shadow-lg rounded-lg flex items-center justify-center relative flex-col lg:flex-row">
          <aside className="hidden lg:block w-1/2 transition border-box">
            <img
              src={downloadIMG}
              className="-ml-10 -mt-10 -mb-1"
              alt="Download for Mac"
            />
          </aside>
          <aside className="block lg:hidden w-full transition border-box">
            <img
              src={downloadMobileIMG}
              className="-mt-10"
              alt="MeshOS is not yet available for mobile devices"
            />
          </aside>
          <section className="w-full lg:w-1/2 p-10 transition border-box">
            {isLoading ? (
              <ActivityIndicator className='flex justify-center' size="large" />
            ) : loadingErrMessage ? (
              <Alert
                title="Something went wrong."
                message={'We were unable to determine the latest download link'}
                status={statusCode.danger}
              />
            ) : (
              <div>
                {newUser &&
                  <>
                    <Type size="heading">Account Activated!</Type>
                    <hr className='my-6' />
                  </>
                }
                <Type>
                  {newUser ?
                    "Download the MeshOS app to get started." :
                    "Download the MeshOS app for Mac to begin."
                  }
                </Type>
                <br />
                <a href={downloadURL ?? undefined} download>
                  <Button marginless full size="large">
                    Download
                  </Button>
                </a>
                <Type className="text-center" size="subtext">requires macOS 11 or greater</Type>
              </div>
            )}
          </section>
        </div>
      </div>
      <div className="h-64 w-full bg-gray-100 -mt-64" />
    </>
  );
};

export default ClientDownload;
