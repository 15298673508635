export const sizeLookup: Record<string, string> = {
  small: 'py-2 px-3 text-xs',
  medium: 'py-2 px-3 text-sm',
  large: 'py-3 px-4 text-lg',
};

export const groupedLookup: Record<string, string> = {
  none: 'rounded',
  left: 'rounded-l mr-0 flex-1',
  center: 'mx-0 flex-1',
  right: 'rounded-r ml-0 flex-1',
};

export const colors = [
  '#EA5D63',
  '#F49B62',
  '#F3BB62',
  '#60F390',
  '#62C3FF',
  '#60A8FF',
  '#AA5EF2',
  '#1A202C',
  '#718096',
  '#F7FAFC',
];

export const defaultColor = colors[6];
