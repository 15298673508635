/**
 * This module was automatically generated by `ts-interface-builder`
 */
import * as t from "ts-interface-checker";
// tslint:disable:object-literal-key-quotes

export const User = t.intersection("UserShallow", t.iface([], {
  "publisherProfile": t.opt(t.iface([], {
    "spaces": t.opt(t.array("WorkspaceShallow")),
    "color": t.opt("string"),
  })),
}));

export const Workspace = t.intersection("WorkspaceShallow", t.iface([], {
  "publisher": "UserShallow",
  "featured": t.opt(t.array("string")),
}));

export const Category = t.intersection("CategoryShallow", t.iface([], {
  "spaces": t.opt(t.array("Workspace")),
  "relatedCategories": t.opt(t.array("CategoryShallow")),
  "subtitle": "string",
  "banner": "string",
}));

export const Featured = t.iface([], {
  "categories": t.array("CategoryShallow"),
  "spaces": t.array("Workspace"),
});

export const Review = t.intersection("ReviewShallow", t.iface([], {
  "author": "UserShallow",
  "product": "WorkspaceShallow",
}));

export const Team = t.intersection("TeamShallow", t.iface([], {
  "members": t.array("UserShallow"),
  "spaces": t.opt(t.array("WorkspaceShallow")),
}));

const exportedTypeSuite: t.ITypeSuite = {
  User,
  Workspace,
  Category,
  Featured,
  Review,
  Team,
};
export default exportedTypeSuite;
