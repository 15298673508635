import React from 'react';

import Type from '../Type';

export interface AppRequirementsProps {
  className?: string;
}

export const AppRequirements: React.FC<AppRequirementsProps> = ({ className }) => {
  const classes = '' + (className ? ' ' + className : '');
  return (
    <div className={classes}>
      <Type size="subheading">Minimum Requirements:</Type>
      <Type size="subtext">macOS Big Sur (11.3)</Type>
      {/* <Type size="subtext">internet connection</Type> */}
      <br />
      <Type size="subheading">Recommended:</Type>
      <Type size="subtext">macOS Ventura (13) or later</Type>
      <Type size="subtext">M1 chip</Type>
      <Type size="subtext">16 GB of memory</Type>
    </div>
  );
};

export default AppRequirements;
