import React from 'react';
import { Helmet } from 'react-helmet-async';
//import { Link } from 'react-router-dom';

import Type from '../../components/Type';

const TermsAndConditions: React.FC = () => {
  const paragraph = 'py-2';
  return (
    <div className='md:max-w-xl lg:max-w-4xl md:mx-auto py-6 my-6 border-box'>
      <Helmet>
        <title>MeshOS: Terms of Service</title>
      </Helmet>
      <Type size="title">MeshOS Inc. Terms of Service</Type>
      <div className='conditions'>
        <Type className={paragraph}><strong>Last Updated: December 18, 2023</strong></Type>
        <Type className={paragraph}>
          If you signed a separate Cover Page to access the Product with the
          same account, and that agreement has not ended, the terms below do not
          apply to you. Instead, your separate Cover Page applies to your use of
          the Product.
        </Type>
        <Type className={paragraph}>
          This Agreement is between MeshOS Inc. and the company or person
          accessing or using the Product. This Agreement consists of: (1) the
          Order Form and (2) the Key Terms, both of which are on the Cover Page
          below, and (3) the Common Paper&nbsp;
          <a href="https://commonpaper.com/standards/cloud-service-agreement/1.0/" target='_blank' rel="noreferrer">
            Cloud Service Agreement Standard Terms Version 1.0
          </a> (“Standard Terms”).
        </Type>
        <Type className={paragraph}>
          Any modifications to the Standard Terms made in the Cover Page will
          control over conflicts with the Standard Terms. Capitalized words have
          the meanings or descriptions given in the Cover Page or the Standard
          Terms.
        </Type>
        <Type className={paragraph}>
          If you are accessing or using the Product on behalf of your company,
          you represent that you are authorized to accept this Agreement on behalf
          of your company. By signing up, accessing, or using the Product,
          Customer indicates its acceptance of this Agreement and agrees to be
          bound by the terms and conditions of this Agreement.
        </Type>

        <Type className={paragraph} size='subtitle'>Cover Page</Type>

        <Type className={paragraph}><em>Order Form</em></Type>

        <Type className={paragraph}>
          <strong>Cloud Service:</strong> MeshOS is a desktop application
          backed by cloud services that gives users persistent working context, in
          large collaborative workspaces.
        </Type>
        <Type className={paragraph}>
          <strong>Subscription Start Date:</strong> The Effective Date
        </Type>
        <Type className={paragraph}>
          <strong>Subscription Period:</strong> 1 month(s)
        </Type>
        <Type className={paragraph}>
          <strong>Non-Renewal Notice Period:</strong> At least 30 days before
          the end of the current Subscription Period.
        </Type>
        <Type className={paragraph}>
          <strong>Use Limitations:</strong> Maximum number of workspaces per account is
          1000. Maximum number of elements per workspace is 256.
        </Type>

        {/* <Type className={paragraph}>
          <strong>Cloud Service Fees:</strong> Section 5.2 of the Standard
          Terms is replaced with: Certain parts of the Product have different
          pricing plans, which are available at Provider’s <Link to="/pricing" target='_blank' rel="noreferrer">pricing page</Link>.
          Within the Payment Period, Customer will pay Provider fees based on the
          Product tier selected at the time of account creation and Customer’s usage
          per Subscription Period. Provider may update Product pricing by giving at
          least 30 days notice to Customer (including by email or notification
          within the Product), and the change will apply in the next Subscription
          Period.
        </Type>
        <Type className={paragraph}>
          <strong>Payment Period:</strong> 5 day(s) from the last day of the
          Subscription Period
        </Type>
        <Type className={paragraph}>
          <strong>Invoice Period:</strong> Monthly
        </Type> */}

        <Type className={paragraph}>
          <strong>Professional Services:</strong> Contact us for development of
          workspaces, custom modules, and additional features, at <a href="mailto:team@meshos.com">team@meshOS.com</a>
        </Type>

        <Type className={paragraph}><em>Key Terms</em></Type>

        <Type className={paragraph}>
          <strong>Customer:</strong> The company or person who accesses or uses
          the Product. If the person accepting this Agreement is doing so on
          behalf of a company, all use of the word &quot;Customer&quot; in the Agreement
          will mean that company.
        </Type>
        <Type className={paragraph}>
          <strong>Provider:</strong> MeshOS Inc.
        </Type>
        <Type className={paragraph}>
          <strong>Effective Date:</strong> The date Customer first accepts this
          Agreement.
        </Type>

        <Type className={paragraph}><strong>Covered Claims:</strong></Type>

        <Type className={paragraph}>
          <strong>Provider Covered Claims:</strong> Any action, proceeding, or
          claim that the Cloud Service, when used by Customer according to the
          terms of the Agreement, violates, misappropriates, or otherwise
          infringes upon anyone else’s intellectual property or other proprietary
          rights.
        </Type>
        <Type className={paragraph}>
          <strong>Customer Covered Claims:</strong> Any action, proceeding, or
          claim that (1) the Customer Content, when used according to the terms of
          the Agreement, violates, misappropriates, or otherwise infringes upon
          anyone else’s intellectual property or other proprietary rights; or (2)
          results from Customer’s breach or alleged breach of Section 2.1
          (Restrictions on Customer).
        </Type>
        <Type className={paragraph}>
          <strong>General Cap Amount:</strong> The fees paid or payable by
          Customer to provider in the 12 month period immediately before the
          claim
        </Type>
        <Type className={paragraph}>
          <strong>Governing Law:</strong> The laws of the State of Delaware
        </Type>
        <Type className={paragraph}>
          <strong>Chosen Courts:</strong> The state or federal courts located
          in Delaware
        </Type>
        <Type className={paragraph}>
          <strong>Notice Address:</strong><br />
          For Provider: team@meshos.com<br />
          For Customer: The main email address on Customer&apos;s Account
        </Type>

        {/* <Type className={paragraph}>
          <em>Attachments and Supplements</em><br />
          <strong>DPA:</strong> <Link to="/dpa" target='_blank' rel="noreferrer">https://www.meshos.com/dpa</Link>
        </Type> */}

      </div>
    </div>
  );
};

export default TermsAndConditions;
