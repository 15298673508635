import React from 'react';

import { Helmet } from 'react-helmet-async';

import Type from '../../components/Type';

import banner404 from '../../assets/images/404.svg';

// because the route for this page runs stand-alone, outside the rest of the
// routes, we have to manually render the root element here
import Root from '../Root';

export function Error404() {
  return (
    <Root>
      <section>
        <Helmet>
          <title>MeshOS: Page Not Found</title>
        </Helmet>
        <div className="container m-auto text-center">
          <img
            className="w-full md:w-2/3 lg:w-1/2 p-8 border-box"
            src={banner404}
            alt="Error 404"
          />
        </div>
        <div className="bg-gray-100 -mt-40 pt-40 pb-20">
          <div className="container m-auto flex items-center flex-col">
            <Type size="title">Uh oh!</Type>
            <Type size="heading">We can&apos;t find the page you&apos;re looking for.</Type>
            <Type size="heading">The links below should help.</Type>
          </div>
        </div>
      </section>
    </Root>
  );
}

export default Error404;
