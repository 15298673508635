import React, { useState } from 'react';

import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope,
  faLock,
  faLongArrowAltRight,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet-async';

import statusCode from '../../constants/statusCode';

import { logIn, logOut, resetPassword, useFirebaseAuth } from '../../api/auth';

import ActivityIndicator from '../../components/ActivityIndicator';
import Type from '../../components/Type';
import InputText from '../../components/InputText';
import Button from '../../components/Button';

import loginIMG from '../../assets/images/login.svg';
import loginMobileIMG from '../../assets/images/login_mobile.svg';

const errorMessages: Record<string, string> = {};
errorMessages['The password is invalid or the user does not have a password.'] =
  'Uh oh! Incorrect email/password combination...';
errorMessages[
  'There is no user record corresponding to this identifier. The user may have been deleted.'
] =
  errorMessages[
    'The password is invalid or the user does not have a password.'
  ];

interface loginVals {
  email: string;
  password: string;
}

interface resetVals {
  email: string;
}

const ResetSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
});

export function Login() {
  const params = useParams();
  const navigate = useNavigate();
  const redirect = params.redirect ?? 'account';
  const [user, reloading] = useFirebaseAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [resetting, setResetting] = useState(false);
  const [sentReset, setSentReset] = useState(false);

  const success = () => {
    setLoading(false);
    setError('');
  };

  const err = (err: Error) => {
    setLoading(false);
    setError(err.message);
    // console.error(err);
  };

  const login = (values: loginVals) => {
    setLoading(true);
    logIn(values.email, values.password)
      .then((loggedInUser) => {
        // redirect if they still need to verify their email
        if (!loggedInUser.emailVerified) {
          navigate('/verifyemail');
          return;
        }
        navigate('/' + decodeURIComponent(redirect));
      })
      .catch(err);
  };

  const logout = () => {
    setLoading(true);
    logOut().then(success).catch(err);
  };

  const reset = (values: resetVals) => {
    setLoading(true);
    setSentReset(false);
    resetPassword(values.email)
      .then(() => {
        success();
        setSentReset(true);
      })
      .catch(err);
  };

  return (
    <div>
      <Helmet>
        <title>MeshOS Login</title>
      </Helmet>
      <div
        className="container sm:p-32 m-auto border-box"
        style={{ maxWidth: '60rem' }}
      >
        <div className="bg-white shadow-lg rounded-lg flex items-center justify-center relative flex-col lg:flex-row">
          <aside className="hidden lg:block w-1/2 transition border-box">
            <img
              src={loginIMG}
              className="-ml-10 -mt-10 -mb-1"
              alt="Welcome Back"
            />
          </aside>
          <aside className="block lg:hidden w-full transition border-box">
            <img src={loginMobileIMG} className="-mt-10" alt="Welcome Back" />
          </aside>
          <section className="w-full lg:w-1/2 p-10 transition border-box">
            {loading || reloading ? (
              <ActivityIndicator />
            ) : user ? (
              <div className="fade-in">
                <Type size="subheading">Logged in as</Type>
                <Type size="heading">
                  <span>{user.email}</span>
                </Type>
                <br />
                <hr />
                <br />
                <Link to={'/' + redirect}>
                  <Button full>
                    <span>
                      Continue <FontAwesomeIcon icon={faLongArrowAltRight} />
                    </span>
                  </Button>
                </Link>
                <Button onClick={logout} color="secondary" full>
                  <span>
                    Sign Out <FontAwesomeIcon icon={faSignOutAlt} />
                  </span>
                </Button>
              </div>
            ) : resetting ? (
              <Formik
                initialValues={{
                  email: '',
                }}
                validationSchema={ResetSchema}
                onSubmit={(values: resetVals) => {
                  reset(values);
                }}
              >
                {(formProps) => (
                  <Form>
                    <Type size="heading">Reset Password</Type>
                    <hr />
                    {error ? (
                      <Type className="text-red-500">
                        <span>
                          {errorMessages[error] ||
                            'Uh oh! Something went wrong...'}
                        </span>
                      </Type>
                    ) : null}
                    {sentReset ? (
                      <Type className="text-green-700">
                        <span>
                          Reset email sent. Please check your email and follow
                          the intructions to reset your password.
                        </span>
                      </Type>
                    ) : null}
                    <br />
                    <InputText
                      field
                      name="email"
                      placeholder="Email"
                      label="Email"
                      type="email"
                      icon={faEnvelope}
                      note={formProps.errors.email}
                      status={
                        formProps.errors.email ? statusCode.danger : statusCode.none
                      }
                    />
                    <div>
                      <Button type="submit" full>
                        <span>
                          Send Reset Email{' '}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </span>
                      </Button>
                      <Button
                        onClick={() => {
                          setSentReset(false);
                          setError(undefined);
                          setResetting(false);
                        }}
                        type="button"
                        framing="outline"
                        full
                        size="small"
                      >
                        <span>Back to Login</span>
                      </Button>
                    </div>
                    <br />
                  </Form>
                )}
              </Formik>
            ) : (
              <Formik
                initialValues={{
                  email: '',
                  password: '',
                }}
                onSubmit={(values: loginVals) => {
                  login(values);
                }}
              >
                <Form>
                  <Type size="heading">Login to MeshOS</Type>
                  <hr />
                  {error ? (
                    <Type className="text-red-500">
                      <span id={'error'}>
                        {errorMessages[error] ||
                          'Uh oh! Something went wrong...'}
                      </span>
                    </Type>
                  ) : null}
                  <br />
                  <InputText
                    field
                    name="email"
                    placeholder="Email"
                    label="Email"
                    type="email"
                    icon={faEnvelope}
                  />
                  <InputText
                    field
                    name="password"
                    placeholder="Password"
                    label="Password"
                    type="password"
                    icon={faLock}
                  />
                  <div>
                    <Button type="submit" full>
                      <span>
                        Login <FontAwesomeIcon icon={faLongArrowAltRight} />
                      </span>
                    </Button>
                    <Button
                      onClick={() => {
                        setSentReset(false);
                        setError(undefined);
                        setResetting(true);
                      }}
                      type="button"
                      framing="outline"
                      full
                      size="small"
                    >
                      <span>Forgot Password</span>
                    </Button>
                  </div>
                  <br />
                </Form>
              </Formik>
            )}
          </section>
        </div>
      </div>
      <div className="h-64 w-full bg-gray-100 -mt-64" />
    </div>
  );
}

export default Login;
