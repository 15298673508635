// check for dev environment
const isDev = process.env.REACT_APP_FIREBASE_DEVELOPMENT ? true : false;
const isLocal = (
  process.env.REACT_APP_AUTH_EMULATOR ||
  process.env.REACT_APP_DATABASE_EMULATOR_HOST ||
  process.env.REACT_APP_FUNCTION_EMULATOR_HOST
) ? true : false;

// MeshOS home page
export const securityPrefix = isLocal ? 'http://' : 'https://';
export const baseURL = isLocal ? 'localhost:3000' : (isDev ? 'meshos-dev.web.app' : 'meshos.com');
export const fullURL = securityPrefix + baseURL;

// open.meshos.com subdomain
export const openerSubdomain = 'open';
export const openerURL = securityPrefix + openerSubdomain + '.' + baseURL;

// Discord links
export const discordInvite = 'https://discord.gg/mnfhsVV9QA';
export const discordDeepLink = 'https://discord.com/channels/1180024499505217547/1180024499505217550';

// application release feed
export const appReleaseFeed = 'https://s3-us-west-1.amazonaws.com/us-west-1-meshos-downloads/releases/osx/beta/channel.xml';

export const supportEmail = "support@meshos.com";