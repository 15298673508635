// react tools
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

// website components
import AppRequirements from '../../components/AppRequirements';
import SignUpForm from '../../components/SignUpForm';

const SignUpPage: React.FC = () => {
  // referral code
  const { ref: refCode } = useParams();
  //console.log(`refCode is "${refCode}"`);

  const title = (
    <Helmet>
      <title>MeshOS: Sign Up</title>
    </Helmet>
  );

  // normal state, show the form
  return (
    <div className='relative w-full mb-6'>
      {title}
      <div className="max-w-md mx-auto lg:min-h-[8rem] p-6 bg-gray-200">
        <SignUpForm refCode={refCode} />
      </div>
      <div className='lg:absolute lg:top-0 lg:mx-0 lg:right-[calc(50vw-14rem-12.625rem-2rem)] lg:mt-0 max-w-max mx-auto p-4 mt-4 bg-blue-100'>
        <AppRequirements />
      </div>
    </div>
  );
};
export default SignUpPage;
