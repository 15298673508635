import { auth } from './firebase';
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
} from "firebase/auth";

import { useAuthState } from 'react-firebase-hooks/auth';

export function useFirebaseAuth() {
  return useAuthState(auth);
}

export async function logIn(email: string, password: string) {
  const response = await signInWithEmailAndPassword(auth, email, password);
  return response.user;
}

export async function logOut() {
  return await signOut(auth);
}

export async function resetPassword(email: string) {
  return await sendPasswordResetEmail(auth, email);
}

export async function changePassword(oldPassword: string, newPassword: string) {
  const user = auth.currentUser;
  if (user?.email) {
    const credential = EmailAuthProvider.credential(user.email, oldPassword);
    await reauthenticateWithCredential(user, credential);
    await updatePassword(user, newPassword);
  }
}
