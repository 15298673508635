import React, { PropsWithChildren } from 'react';

export interface ModalProps extends PropsWithChildren {
  open: boolean;
  toggleState?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const Modal: React.FC<ModalProps> = (props) => {
  return props.open ? (
    <div
      className="fixed inset-0 z-50 overflow-auto flex"
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.4)' }}
    >
      {props.toggleState &&
        <button
          className="w-full outline-none fixed inset-0 overflow-auto flex bg-transparent"
          type="button"
          onClick={props.toggleState}
        />
      }
      <div className="relative p-8 bg-white w-full max-w-md m-auto flex-col flex rounded shadow-lg">
        {props.children}
      </div>
    </div>
  ) : null;
};

export default Modal;
