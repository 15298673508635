import React, { useState } from 'react';

import { Navigate, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { useAuthProfile } from '../../api';
import { logOut } from '../../api/auth';

import Hero from '../../components/Hero';
import Alert from '../../components/Alert';
import Button from '../../components/Button';
import ActivityIndicator from '../../components/ActivityIndicator';
import AccountForm from './AccountForm';

import bannerIMG from '../../assets/images/AccountMain/heroBanner.svg';

export function AccountMain() {
  const [user, loading, error, refresh] = useAuthProfile();
  const [curInitials, setInitials] = useState('');
  const [curColor, setColor] = useState('');
  const [curPubColor, setPubColor] = useState('');
  const [updateLoading, setUpdateLoading] = useState(false);
  const navigate = useNavigate();

  if (!loading && user) {
    if (!curInitials) {
      setInitials(user.initials);
    }
    if (!curColor) {
      setColor(user.color);
    }
    if (user.publisherProfile && !curPubColor) {
      setPubColor(user.publisherProfile.color);
    }
  }

  return (
    <div>
      <Helmet>
        <title>MeshOS: Account Management</title>
      </Helmet>
      <Hero
        title="Account Management"
        subtitle="Change your MeshOS account details here."
        imageURL={bannerIMG}
      />
      <div className="bg-gray-100">
        {loading ? (
          <ActivityIndicator size={'large'} />
        ) : user ? (
          <div className="fade-in container m-auto pl-6 pr-6 pb-6 -mt-6 border-box">
            <br />
            <AccountForm
              user={user}
              load={updateLoading}
              setLoadFunc={setUpdateLoading}
              curInitials={curInitials}
              setInitialsFunc={setInitials}
              refresh={refresh}
            />
            {/*user.publisherProfile ? (
              <div>
                <PublisherForm
                  user={user}
                  curPubColor={curPubColor}
                  setPubColor={setPubColor}
                  updateLoading={updateLoading}
                  setUpdateLoading={setUpdateLoading}
                  refresh={refresh}
                />
                <Link to="/publish/dashboard">
                  <Button full color="secondary">
                    <span>
                      View My Publisher Dashboard{' '}
                      <FontAwesomeIcon icon={faLongArrowAltRight} />
                    </span>
                  </Button>
                </Link>
              </div>
            ) : (
              <div>
                <Type size="heading">Become a Publisher</Type>
                <Container
                  layout="100"
                  content={[
                    <div className="p-6 w-full flex items-center justify-center border-box">
                      <div className="text-6xl flex items-center text-white p-6 -m-6 mr-6 bg-blue-700 h-full">
                        <FontAwesomeIcon icon={faTools} />
                      </div>
                      <div className="w-full">
                        <Type size="heading">
                          Looks like you're not a publisher yet... ready to
                          change that?
                        </Type>
                        <hr />
                        <Type>
                          Publishers can earn a steady stream of monthly income
                          just by sharing their own creations and tools with the
                          world. If you've built something useful, odds are good
                          that someone else would find it useful too! Get
                          started today and we'll have your creations on the
                          Marketplace in minutes.
                        </Type>
                        <br />
                        <Link to="/publish/getstarted">
                          <Button color="secondary" marginless>
                            Get Started
                          </Button>
                        </Link>
                      </div>
                    </div>,
                  ]}
                />
              </div>
            )*/}
            <br />
            <hr />
            <br />
            <Button
              framing="outline"
              color="secondary"
              onClick={() => {
                logOut()
                  .then(() => navigate('/'))
                  .catch((e) => {
                    console.error(e);
                  });
              }}
            >
              Log Out
            </Button>
          </div>
        ) : error ? (
          <Alert title={'Error!'} message={error} status={4} />
        ) : (
          <Navigate to="/login" />
        )}
      </div>
    </div>
  );
}

export default AccountMain;
