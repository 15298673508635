import React, { PropsWithChildren } from 'react';

import propOrDefault from '../../utils/propOrDefault';

const sizeLookup: Record<string, string> = {
  title: 'font-sans font-bold mt-2 mb-2',
  subtitle: 'font-sans opacity-75 mb-1 font-bold mt-0',
  heading: 'font-sans mt-1 mb-2 font-normal',
  subheading: 'font-sans uppercase tracking-wide opacity-75 font-bold mb-2 mt-0',
  subtext: 'font-sans mt-2 italic mb-0',
  paragraph: 'font-sans leading-normal mt-1 mb-1',
};

const fontSizeLookup: Record<string, string> = {
  title: ' text-4xl',
  subtitle: ' text-xl',
  heading: ' text-xl',
  subheading: ' text-xs',
  subtext: ' text-xs',
  paragraph: ' text-base',
};

export interface TypeProps extends PropsWithChildren {
  size?:
    | 'title'
    | 'subtitle'
    | 'heading'
    | 'subheading'
    | 'subtext'
    | 'paragraph';
  inline?: boolean;
  className?: string;
  style?: React.CSSProperties;
  link?: boolean;
}

export const Type: React.FC<TypeProps> = (props) => {
  const size = propOrDefault(props.size, 'paragraph');
  const inline = propOrDefault(props.inline, false);
  const link = propOrDefault(props.link, false);

  let fontSize = fontSizeLookup[size];
  if (props.className) {
    if (props.className.includes('text-xs')
      || props.className.includes('text-sm')
      || props.className.includes('text-base')
      || props.className.includes('text-lg')
      || props.className.includes('text-xl')
      || props.className.includes('text-2xl')
      || props.className.includes('text-3xl')
      || props.className.includes('text-4xl')
      || props.className.includes('text-5xl')) {
      fontSize = '';
    }
  }

  // default to left text alignment unless parent specifies otherwise
  let alignment = ' text-left';
  if (props.className) {
    if (props.className.includes('text-left')
      || props.className.includes('text-center')
      || props.className.includes('text-right')
      || props.className.includes('text-justify')
      || props.className.includes('text-start')
      || props.className.includes('text-end')) {
      alignment = '';
    }
  }

  const cn =
    sizeLookup[size] +
    (link ? ' text-gray-700 hover:text-blue-700 hover:underline' : '') +
    (inline ? ' inline-block' : ' block');
  const elementProps = {
    className: cn + alignment + fontSize + ' ' + props.className,
    style: props.style,
  };

  switch (size) {
  case 'title':
    return <h1 {...elementProps}>{props.children}</h1>;
  case 'subtitle':
    return <h2 {...elementProps}>{props.children}</h2>;
  case 'heading':
    return <h3 {...elementProps}>{props.children}</h3>;
  case 'subheading':
    return <h4 {...elementProps}>{props.children}</h4>;
  default:
    return <p {...elementProps}>{props.children}</p>;
  }
};

export default Type;
