import React, { useState } from 'react';

import statusCode from '../../constants/statusCode';
import statusColors, { lightColors, darkColors, icons } from '../../constants/statusColors';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Type from '../Type';

export interface AlertProps {
  title: string;
  message: string | JSX.Element;
  status: statusCode;
  closeable?: boolean;
}

export function Alert(props: AlertProps) {
  const colorL = lightColors[statusCode[props.status]];
  const color = statusColors[statusCode[props.status]];
  const colorD = darkColors[statusCode[props.status]];
  const icon = icons[statusCode[props.status]];
  const closeable = props.closeable !== false;

  const [active, setActive] = useState(true);

  return active ? (
    <div
      className={
        'bg-' +
        colorL +
        '  border border-t-4 border-solid border-' +
        color +
        ' text-' +
        colorD +
        ' px-4 py-3 rounded relative shadow-md mt-4 mb-4'
      }
      role="alert"
    >
      <Type inline>
        <span>
          <FontAwesomeIcon icon={icon} className="mr-3" />
          <strong>{props.title}</strong> {props.message}
        </span>
      </Type>
      {closeable &&
        <button
          onClick={() => setActive(false)}
          className="absolute top-0 bottom-0 right-0 px-4 py-3 bg-transparent border-none"
        >
          <svg
            className={'fill-current h-5 w-6 text-' + color}
            role="button"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <title>Close</title>
            <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
          </svg>
        </button>
      }
    </div>
  ) : null;
}

export default Alert;
