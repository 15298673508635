import React from 'react';

import { toast } from 'react-toastify';
import { Form, Formik } from 'formik';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope, faLock, faSmile } from '@fortawesome/free-solid-svg-icons';

import { User } from '../../../constants/database';
import initials from '../../../utils/initials';
import isDataURL from '../../../utils/isDataURL';
import { changePassword } from '../../../api/auth';
import updateProfile from '../../../utils/updateProfile';

import Type from '../../../components/Type';
import Container from '../../../components/Container';
import InputText from '../../../components/InputText';
import UserLink from '../../../components/UserLink';
import InputColor from '../../../components/InputColor';
import Button from '../../../components/Button';
import ProfileBanner from '../../../components/ProfileBanner';
// import Button from '../../../components/Button';

export interface AccountFormProps {
  user: User;
  load: boolean;
  setLoadFunc: (val: boolean) => void;
  curInitials: string;
  setInitialsFunc: (val: string) => void;
  refresh?: () => void;
}

type AccountFormValues = Pick<User, "displayName" | "color" | "email" | "image">;

export function AccountForm(props: AccountFormProps) {
  const user = props.user;
  const updateLoading = props.load;
  const setUpdateLoading = props.setLoadFunc;
  const setInitials = props.setInitialsFunc;
  const curInitials = props.curInitials;
  return (
    <section className="w-full">
      <Type size="heading">My Profile</Type>
      <Container
        layout="50-50"
        padding="p-6"
        content={[
          <Formik<AccountFormValues> key="form1"
            initialValues={{
              displayName: user.displayName,
              color: user.color,
              email: user.email ? user.email : '',
              image: user.image,
            }}
            onSubmit={(vals) => {
              if (isDataURL(vals.image)) {
                toast.info(
                  'Uploading new profile picture, this may take a moment...'
                );
              }
              void updateProfile(vals, user, setUpdateLoading)
                .then(() => {
                  if (props.refresh) {
                    props.refresh();
                  }
                });
            }}
          >
            {(formProps) => (
              <Form className={'flex flex-col justify-between w-full'}>
                <span className="w-full">
                  <Container
                    layout="33-66"
                    unframed
                    unshadowed
                    marginless
                    overflow
                    content={[
                      <ProfileBanner
                        key="profile"
                        curColor={formProps.values.color}
                        initialSrc={user.image}
                        name="image"
                      />,
                      <div key="inputs" className="w-full">
                        <div className="w-full">
                          <div className="flex items-center">
                            <div className="flex-1">
                              <InputText
                                label="Full Name"
                                name="displayName"
                                placeholder="Full Name"
                                icon={faSmile}
                                field
                                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                setFieldValue={formProps.setFieldValue}
                                onChange={(val: string) =>
                                  setInitials(initials(val))
                                }
                              />
                            </div>
                            <div className="flex-0 mt-2 ml-3">
                              <UserLink
                                unlinked
                                user={{
                                  ...user,
                                  initials: curInitials,
                                  color: formProps.values.color,
                                }}
                                id={'initials'}
                                forceInitials
                              />
                            </div>
                          </div>
                          <InputColor
                            label="Profile Color"
                            content="This color will be used when your profile photo cannot be shown. "
                            onChange={(val) => {
                              void formProps.setFieldValue('color', val);
                            }}
                            name="color"
                            id={'profileColor'}
                          />
                          <InputText
                            label="Email"
                            placeholder={formProps.values.email ?? user.email ?? ''}
                            type="email"
                            icon={faEnvelope}
                            disabled
                          />
                        </div>
                        <div className="w-full text-right">
                          <Button
                            loading={updateLoading}
                            type={'submit'}
                            marginless
                          >
                            Save Changes
                          </Button>
                        </div>
                      </div>,
                    ]}
                  />
                </span>
              </Form>
            )}
          </Formik>,
          <Formik key="form2"
            initialValues={{ oldPass: '', newPass: '', confirmPass: '' }}
            onSubmit={(vals, { resetForm }) => {
              setUpdateLoading(true);
              if (vals.newPass === vals.confirmPass) {
                changePassword(vals.oldPass, vals.newPass)
                  .then(() => {
                    toast.success('Password changed!');
                    setUpdateLoading(false);
                    resetForm();
                  })
                  .catch((err) => {
                    toast.warn(
                      'Uh oh, your password could not be changed. Please wait a moment and try again.'
                    );
                    console.error(err);
                    setUpdateLoading(false);
                  });
              } else {
                toast.warn('New passwords must match!');
                setUpdateLoading(false);
              }
            }}
          >
            <Form className={'flex flex-col justify-between w-full'}>
              <div>
                <InputText
                  label="Current Password"
                  placeholder="Current Password"
                  type="password"
                  icon={faLock}
                  name={'oldPass'}
                  field
                />
                <InputText
                  label="New Password"
                  placeholder={'New Password'}
                  type={'password'}
                  icon={faLock}
                  name={'newPass'}
                  field
                />
                <InputText
                  label="Confirm New Password"
                  placeholder="Confirm New Password"
                  type="password"
                  name="confirmPass"
                  icon={faLock}
                  field
                />
              </div>
              <div className="w-full text-right">
                <Button loading={updateLoading} type={'submit'} marginless>
                  Change Password
                </Button>
              </div>
            </Form>
          </Formik>,
        ]}
      />
      <br />
    </section>
  );
}

export default AccountForm;
