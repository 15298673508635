/* eslint-disable @typescript-eslint/no-floating-promises */
import { useState, useEffect, useCallback } from 'react';
import { useFirebaseAuth } from './auth';

import { User, Workspace, Category, Featured } from '../constants/database';
import { CategoryShallow } from '../constants/database-shallow';
import {
  firebaseIdToMeshId,
  publisherSlugToMeshId,
  meshIdToPublicProfile,
  productSlugToMeshId,
  meshIdToProduct,
  categorySlugToCategory,
  getCurrentlyFeatured,
  getAllCategories,
  meshIdToUnpublishedWorkspaceName,
} from './helpers';
import { parseReleaseFeed } from '../utils/releaseFeedParser';


type LoadingHook<T, E> = [value: T | null, isLoading: boolean, error: E | null];


type AuthProfileState = [user: User | null, isLoading: boolean, error: string | null, refresh: () => void];

export const useAuthProfile = (): AuthProfileState => {
  const [authUser, authUserLoading, authUserError] = useFirebaseAuth();
  const [refresh, forceRefresh] = useState(false);
  const triggerRefresh = useCallback(() => {
    forceRefresh(!refresh);
  }, [refresh]);

  const [authProfileState, setAuthProfileState] = useState<AuthProfileState>([
    null,
    true,
    null,
    triggerRefresh,
  ]);

  useEffect(() => {
    async function fetchAuthProfile() {
      if (authUser) {
        try {
          const meshId = await firebaseIdToMeshId(authUser.uid);
          // during sign up, while waiting for email verification,
          // new users can have a Firebase account without a Mesh ID
          if (!meshId) {
            setAuthProfileState([
              null,
              false,
              'User awaiting email verification',
              triggerRefresh,
            ]);
            return;
          }
          const profile = await meshIdToPublicProfile(meshId);
          if (authUser.email) {
            profile.email = authUser.email;
          }
          setAuthProfileState([profile, false, null, triggerRefresh]);
        } catch (e) {
          console.warn('failed to fetch user profile:', e);
          setAuthProfileState([
            null,
            false,
            'Failed to fetch user profile.',
            triggerRefresh,
          ]);
        }
      } else {
        setAuthProfileState([
          null,
          authUserLoading,
          authUserError ? 'User is not logged in.' : null,
          triggerRefresh,
        ]);
        if (authUserError) {
          console.error('Firebase Error: ', authUserError);
        }
      }
    }
    fetchAuthProfile();
  }, [authUser, authUserLoading, authUserError, refresh, triggerRefresh]);

  return authProfileState;
};

type PublisherBySlugHook = LoadingHook<User, string>;

export const usePublisherBySlug = (slug: string | undefined): PublisherBySlugHook => {
  const [publisherBySlugState, setPublisherBySlugState] = useState<PublisherBySlugHook>([null, true, null]);

  useEffect(() => {
    async function fetchPublisherBySlug() {
      if (slug) {
        try {
          const meshId = await publisherSlugToMeshId(slug);
          const profile = await meshIdToPublicProfile(meshId);
          setPublisherBySlugState([profile, false, null]);
        } catch (e) {
          console.warn('failed to fetch publisher profile:', e);
          setPublisherBySlugState([
            null,
            false,
            'Failed to fetch publisher profile.',
          ]);
        }
      } else {
        setPublisherBySlugState([null, false, 'No publisher URL provided.']);
      }
    }
    fetchPublisherBySlug();
  }, [slug]);

  return publisherBySlugState;
};

type ProductBySlugHook = LoadingHook<Workspace, string>;

export const useProductBySlug = (slug: string | undefined): ProductBySlugHook => {
  const [productBySlugState, setProductBySlugState] = useState<
    ProductBySlugHook
  >([null, true, null]);

  useEffect(() => {
    function fetchProductBySlug() {
      if (slug) {
        productSlugToMeshId(slug)
          .then(meshIdToProduct)
          .then((product) => {
            setProductBySlugState([product, false, null]);
          })
          .catch((err) => {
            setProductBySlugState([
              null,
              false,
              'Failed to fetch product profile.',
            ]);
            console.error(err);
          });
      } else {
        setProductBySlugState([null, false, 'No product URL provided.']);
      }
    }
    fetchProductBySlug();
  }, [slug]);

  return productBySlugState;
};

type ProductByMeshIdHook = LoadingHook<Workspace, string>;

export const useProductByMeshId = (meshId: string | undefined): ProductByMeshIdHook => {
  const [productByMeshIdState, setProductByMeshIdState] = useState<
    ProductByMeshIdHook
  >([null, true, null]);

  useEffect(() => {
    function fetchProductByMeshId() {
      if (meshId) {
        meshIdToProduct(meshId)
          .then((product) => {
            setProductByMeshIdState([product, false, null]);
          })
          .catch((err) => {
            setProductByMeshIdState([
              null,
              false,
              'Failed to fetch product profile.',
            ]);
            console.error(err);
          });
      } else {
        setProductByMeshIdState([null, false, 'No product ID provided.']);
      }
    }
    fetchProductByMeshId();
  }, [meshId]);

  return productByMeshIdState;
};

type CategoryBySlugHook = LoadingHook<Category, string>;

export const useCategoryBySlug = (slug: string | undefined): CategoryBySlugHook => {
  const [categoryBySlugState, setCategoryBySlugState] = useState<
    CategoryBySlugHook
  >([null, true, null]);

  useEffect(() => {
    function fetchCategoryBySlug() {
      if (slug) {
        categorySlugToCategory(slug)
          .then((category) => {
            setCategoryBySlugState([category, false, null]);
          })
          .catch((err) => {
            setCategoryBySlugState([
              null,
              false,
              'Failed to fetch category details.',
            ]);
            console.error(err);
          });
      } else {
        setCategoryBySlugState([null, false, 'No category URL provided.']);
      }
    }
    fetchCategoryBySlug();
  }, [slug]);

  return categoryBySlugState;
};

type CurrentlyFeaturedHook = LoadingHook<Featured, string>;

export const useCurrentlyFeatured = (week?: string): CurrentlyFeaturedHook => {
  const [currentlyFeaturedState, setCurrentlyFeaturedState] = useState<
    CurrentlyFeaturedHook
  >([null, true, null]);

  useEffect(() => {
    function fetchCurrentlyFeatured() {
      getCurrentlyFeatured(week)
        .then((featured) => {
          setCurrentlyFeaturedState([featured, false, null]);
        })
        .catch((err) => {
          setCurrentlyFeaturedState([
            null,
            false,
            'Failed to fetch featured data.',
          ]);
          console.error(err);
        });
    }
    fetchCurrentlyFeatured();
  }, [week]);

  return currentlyFeaturedState;
};

type AllCategoriesHook = LoadingHook<CategoryShallow[], string>;

export const useAllCategories = (): AllCategoriesHook => {
  const [allCategoriesState, setAllCategoriesState] = useState<
    AllCategoriesHook
  >([null, true, null]);

  useEffect(() => {
    function fetchAllCategories() {
      getAllCategories()
        .then((categories) => {
          setAllCategoriesState([categories, false, null]);
        })
        .catch((err) => {
          setAllCategoriesState([
            null,
            false,
            'Failed to fetch category data.',
          ]);
          console.error(err);
        });
    }
    fetchAllCategories();
  }, []);

  return allCategoriesState;
};

type UnpublishedWorkspaceNameHook = LoadingHook<string, string>;

export const useUnpublishedWorkspaceName = (id: string): UnpublishedWorkspaceNameHook => {
  const [
    curUnpublishedWorkspaceNameState,
    setUnpublishedWorkspaceNameState,
  ] = useState<UnpublishedWorkspaceNameHook>([null, true, null]);

  useEffect(() => {
    function fetchUnpublishedWorkspaceName() {
      meshIdToUnpublishedWorkspaceName(id)
        .then((name) => {
          setUnpublishedWorkspaceNameState([name, false, null]);
        })
        .catch((err) => {
          setUnpublishedWorkspaceNameState([
            null,
            false,
            'Failed to fetch workspace name.',
          ]);
          console.error(err);
        });
    }
    fetchUnpublishedWorkspaceName();
  }, [id]);

  return curUnpublishedWorkspaceNameState;
};

type LatestReleaseURLHook = LoadingHook<string, string>;

export const useLatestReleaseURL = (): LatestReleaseURLHook => {
  const [latestReleaseURL, setLatestReleaseURL] = useState('');
  const [loadingErrMessage, setLoadingErrMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    async function fetchLatestReleaseURL() {
      setIsLoading(true);
      try {
        const url = await parseReleaseFeed();
        if (!ignoreResult) {
          setLatestReleaseURL(url);
        }
      } catch (e) {
        setLatestReleaseURL('');
        setLoadingErrMessage(String(e));
      } finally {
        setIsLoading(false);
      }
    }
    let ignoreResult = false;
    fetchLatestReleaseURL();
    return () => {
      ignoreResult = true;
    };
  }, []);
  return [latestReleaseURL, isLoading, loadingErrMessage];
};
