// react stuff
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Navigate } from 'react-router-dom';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope as EnvelopeIcon } from '@fortawesome/free-solid-svg-icons';

// utilities
import { useFirebaseAuth } from '../../api/auth';
import statusCode from '../../constants/statusCode';
import {
  createMeshUser,
  sendVerifyEmail,
} from '../../api/helpers';

// our components
import ActivityIndicator from '../../components/ActivityIndicator';
import Alert from '../../components/Alert';
import Button from '../../components/Button';
import Type from '../../components/Type';

export const VerifyEmail: React.FC = () => {
  const [user, authLoading, authError] = useFirebaseAuth();
  const [isCreating, setIsCreating] = useState(false);
  const [meshAccountCreated, setMeshAccountCreated] = useState(false);
  const [createErr, setCreateErr] = useState<unknown>(null);
  const [resendClicked, setResendClicked] = useState(false);

  const title = (
    <Helmet>
      <title>MeshOS: Verify Email</title>
    </Helmet>
  );

  // errors loading auth
  if (authError) {
    console.error(authError);
    return (
      <>
        {title}
        <div className="container sm:py-32 m-auto border-box max-w-3xl">
          <Alert
            title="Something went wrong!"
            status={statusCode.danger}
            message="Unable to determine user status. Please reload and try again."
            closeable={false}
          />
        </div>
      </>
    );
  }

  // errors creating Mesh user
  if (createErr) {
    console.error(createErr);
    return (
      <>
        {title}
        <div className="container sm:py-32 m-auto border-box max-w-3xl">
          <Alert
            title="Something went wrong!"
            status={statusCode.danger}
            message="Failed to activate account. Please reload and try again.."
            closeable={false}
          />
        </div>
      </>
    );
  }

  // auth status is loading (should only take miliseconds)
  if (authLoading) {
    return (
      <>
        {title}
        <ActivityIndicator className='flex justify-center' size="large" />
      </>
    );
  }

  // redirect to login page if not logged in
  if (!user) {
    return <Navigate to="/login/verifyemail" replace />;
  }

  // Mesh account is being created, could take a few seconds
  if (isCreating) {
    return (
      <>
        {title}
        <Type size='subtitle'>Activating your account...</Type>
        <ActivityIndicator className='flex justify-center' size="large" />
      </>
    );
  }

  // email already verified?
  if (user.emailVerified) {
    if (meshAccountCreated) {
      // redirect to download page
      return <Navigate to="/download/newUser" />;
    }

    // create the Mesh part of the account
    setIsCreating(true);
    createMeshUser()
      .then((_resp) => {
        //console.log('create user response: ', _resp);
        setIsCreating(false);
        setMeshAccountCreated(true);
      })
      .catch((e) => {
        setIsCreating(false);
        setCreateErr(e);
      });

    return (
      <>
        {title}
        <Type size='subtitle'>Activating your account...</Type>
        <ActivityIndicator className='flex justify-center' size="large" />
      </>
    );
  }

  // normal state, show the message
  return (
    <>
      {title}
      <div className='w-full flex flex-col items-center gap-y-3 mb-6'>
        <FontAwesomeIcon
          className='size-45 sm:size-60 text-gray-500'
          icon={EnvelopeIcon}
        />
        <Type size="title">Verify your email address</Type>
        <Type>
          We&apos;ve sent an email to <code className='bg-gray-200'>{user.email}</code> to verify your email address.<br />
          Click the link in that email to activate your account.
        </Type>
        {resendClicked ?
          <Type className="p-6" size="subheading">Email sent</Type>
          :
          <Button
            onClick={() => {
              setResendClicked(true);
              sendVerifyEmail()
                .then(() => {
                  console.log('verification email sent');
                })
                .catch((e) => {
                  console.error('failed to send verification email:', e);
                });
            }}
          >
            Resend
          </Button>
        }
      </div>
    </>
  );
};

export default VerifyEmail;
