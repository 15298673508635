import { Jimp } from "jimp";

import { uploadDataURLToRef, loadImageFromRef } from './helpers';

import isDataURL from '../utils/isDataURL';

export async function resizeAndUploadImage(
  ref: string,
  dataOrUrl: string,
  width?: number,
  height?: number
) {
  const w = width ?? 256;
  const h = height ?? width ?? 256;

  if (isDataURL(dataOrUrl)) {
    const image = await Jimp.read(dataOrUrl);
    const resizedBase64 = await image
      .resize({ w, h })
      .getBase64('image/jpeg');

    await uploadDataURLToRef(ref, resizedBase64);
    const imageURL = await loadImageFromRef(ref);
    return imageURL;
  } else {
    return dataOrUrl;
  }
}
