import React from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import * as Sentry from "@sentry/react";
import 'pretty-checkbox/dist/pretty-checkbox.min.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import 'react-image-crop/dist/ReactCrop.css';
import 'cropperjs/dist/cropper.css';
import './styles/index.css';
import './styles/tailwind.css';
import Router from './components/Router';

if (process.env.REACT_APP_FIREBASE_DEVELOPMENT ||
  process.env.REACT_APP_AUTH_EMULATOR ||
  process.env.REACT_APP_DATABASE_EMULATOR_HOST ||
  process.env.REACT_APP_FUNCTION_EMULATOR_HOST)
{
  console.warn("using dev project credentials");
} else {
  // initialize the Sentry logging system
  Sentry.init({
    dsn: "https://bac90aeecc3b03246f8b1f84334d6a1b@o4507935701073920.ingest.us.sentry.io/4507935706251264",
    integrations: [],
  });
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <RouterProvider router={Router} />
    </HelmetProvider>
  </React.StrictMode>
);

