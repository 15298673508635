function initials(name: string): string {
  const allInitials = name.split(/[\s-]+/).map((w) => w.charAt(0).toUpperCase());
  if (allInitials.length > 2) {
    return (
      '' +
      allInitials[0] +
      allInitials[allInitials.length - 2] +
      allInitials[allInitials.length - 1]
    );
  }
  return allInitials.join('');
}

export default initials;
