import React from 'react';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';

// import Button from '../../Button';
import InputUploader from '../InputUploader';

export interface ProfileBannerProps {
  curColor: string;
  initialSrc: string | undefined;
  name: string;
}

const bannerClass =
  'p-6 md:p-1 flex justify-center items-center w-full mb-6 md:-mb-6 md:mr-6 -m-6';
// With sync button: "p-6 md:p-2 flex flex-col justify-center items-center w-full mb-6 md:-mb-6 md:mr-6 md:-m-6 rounded overflow-hidden md:rounded-none border-box"

export function ProfileBanner(props: ProfileBannerProps) {
  return (
    <div
      className={bannerClass}
      style={{ backgroundColor: props.curColor }}
      id={props.name}
    >
      {/* <div className="flex-1" /> */}
      <div className="relative flex justify-center">
        <InputUploader
          profile
          name={props.name}
          initialSrc={props.initialSrc}
        />
      </div>
      {/* <div className="flex-1" />
      <div className="-m-6 -mb-8 mt-2 md:-m-2 md:-mb-3">
        <Button
          grouped="center"
          full
          color="dark"
          size="small"
          type="button"
        >
          <span>
            <span className="mr-2">
              <FontAwesomeIcon icon={faSyncAlt} />
            </span>
            Sync Photos
          </span>
        </Button>
      </div>*/}
    </div>
  );
}

export default ProfileBanner;
