import { initializeApp, FirebaseApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from '@firebase/auth';
import { getDatabase, connectDatabaseEmulator } from '@firebase/database';
import { getFunctions, connectFunctionsEmulator } from '@firebase/functions';

// dev project credentials
const devConfig = {
  apiKey: 'AIzaSyBnqrq0jV6JgltHToK1iAEul5CV0Si--FE',
  authDomain: 'meshos-dev.firebaseapp.com',
  databaseURL: 'https://meshos-dev.firebaseio.com',
  projectId: 'meshos-dev',
  storageBucket: 'meshos-dev.appspot.com',
  messagingSenderId: '105500724189',
  appId: '1:105500724189:web:242370d7de4ba8bc'
};

const prodConfig = {
  apiKey: 'AIzaSyD58_WTJTRf-JdSA-iywLzYL5LbCmPnXww',
  authDomain: 'meshos-app.firebaseapp.com',
  databaseURL: 'https://meshos-app.firebaseio.com',
  projectId: 'meshos-app',
  storageBucket: 'meshos-app.appspot.com',
  messagingSenderId: '675412614068',
  appId: '1:675412614068:web:b6d1e1050b9fa301'
};

let config = prodConfig;
if (process.env.REACT_APP_FIREBASE_DEVELOPMENT ||
  process.env.REACT_APP_AUTH_EMULATOR ||
  process.env.REACT_APP_DATABASE_EMULATOR_HOST ||
  process.env.REACT_APP_FUNCTION_EMULATOR_HOST)
{
  config = devConfig;
}

// initialize Firebase App
const app: FirebaseApp = initializeApp(config);

// initialize Firebase Auth
const auth = getAuth(app);
if (process.env.REACT_APP_AUTH_EMULATOR) {
  console.warn(`using Firebase Auth emulator at ${process.env.REACT_APP_AUTH_EMULATOR}`);
  connectAuthEmulator(auth, process.env.REACT_APP_AUTH_EMULATOR);
}

// initialize Firebase DB
const db = getDatabase(app);
if (process.env.REACT_APP_DATABASE_EMULATOR_HOST) {
  console.warn(`using Firebase DB emulator at ${process.env.REACT_APP_DATABASE_EMULATOR_HOST}:${process.env.REACT_APP_DATABASE_EMULATOR_PORT}`);
  connectDatabaseEmulator(
    db,
    process.env.REACT_APP_DATABASE_EMULATOR_HOST,
    parseInt(process.env.REACT_APP_DATABASE_EMULATOR_PORT!)
  );
}

// initialize Firebase functions
const functions = getFunctions(app);
if (process.env.REACT_APP_FUNCTION_EMULATOR_HOST) {
  console.warn(`using Firebase Functions emulator at ${process.env.REACT_APP_FUNCTION_EMULATOR_HOST}:${process.env.REACT_APP_FUNCTION_EMULATOR_PORT}`);
  connectFunctionsEmulator(
    functions,
    process.env.REACT_APP_FUNCTION_EMULATOR_HOST,
    parseInt(process.env.REACT_APP_FUNCTION_EMULATOR_PORT!)
  );
}

export { auth, db, functions };
export default app;
