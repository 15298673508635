import React, { PropsWithChildren } from 'react';

import propOrDefault from '../../utils/propOrDefault';
import ActivityIndicator from '../ActivityIndicator';

const sizeLookup: Record<string, string> = {
  small: 'py-2 px-4 text-sm',
  medium: 'py-3 px-6 text-base',
  large: 'py-4 px-6 text-lg',
  hero: 'py-4 px-8 text-xl',
};

const sizeMargins: Record<string, string> = {
  small: 'm-1',
  medium: 'm-1',
  large: 'm-2',
  hero: 'mt-4 mr-4 mb-4',
};

const styleColorLookup: Record<string, Record<string, string>> = {
  primary: {
    primary: 'shadow border-none bg-blue-600 hover:bg-blue-700 text-white',
    secondary: 'shadow border-none bg-blue-800 hover:bg-blue-900 text-white',
    light: 'shadow border-none bg-gray-100 hover:bg-gray-300 text-blue-900',
    dark: 'shadow border-none bg-gray-800 hover:bg-gray-900 text-blue-100',
  },
  outline: {
    primary:
      'shadow bg-transparent border border-blue-600 hover:bg-blue-600 text-blue-600 hover:text-white',
    secondary:
      'shadow bg-transparent border border-blue-800 hover:bg-blue-800 text-blue-900 hover:text-white',
    light:
      'shadow bg-transparent border border-blue-100 hover:bg-blue-100 text-blue-100 hover:text-gray-900',
    dark:
      'shadow bg-transparent border border-gray-800 hover:bg-gray-800 text-gray-800 hover:text-white',
  },
  unframed: {
    primary:
      'bg-transparent border-none hover:bg-gray-200 text-blue-600 hover:text-blue-800',
    secondary:
      'bg-transparent border-none hover:bg-gray-200 text-blue-900 hover:text-black',
    light:
      'bg-transparent border-none hover:bg-blue-700 text-blue-100 hover:text-white',
    dark:
      'bg-transparent border-none hover:bg-gray-200 text-gray-800 hover:text-black',
  },
};

const groupedLookup = {
  none: 'rounded',
  left: 'rounded-l mr-0 flex-1',
  center: 'mx-0 flex-1',
  right: 'rounded-r ml-0 flex-1',
  bottom: 'rounded-b mt-0',
};

const inlineClass: string = 'inline-block';
const blockClass: string = 'block w-full mx-0';

const disabledClass: string = 'opacity-50 cursor-not-allowed';

export interface ButtonProps extends PropsWithChildren {
  size?: 'small' | 'medium' | 'large' | 'hero';
  framing?: 'primary' | 'outline' | 'unframed';
  color?: 'primary' | 'secondary' | 'light' | 'dark';
  grouped?: 'none' | 'left' | 'center' | 'right' | 'bottom';
  type?: 'button' | 'submit' | 'reset' | undefined;
  className?: string;
  full?: boolean;
  disabled?: boolean;
  marginless?: boolean;
  loading?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const Button: React.FC<ButtonProps> = (props) => {
  const size = propOrDefault(props.size, 'medium');
  const framing = propOrDefault(props.framing, 'primary');
  const color = propOrDefault(props.color, 'primary');
  const grouped = propOrDefault(props.grouped, 'none');
  const full = propOrDefault(props.full, false);
  const disabled = propOrDefault(props.disabled, false);
  const marginless = propOrDefault(props.marginless, false);

  return (
    <button
      type={props.type}
      onClick={disabled ? undefined : props.onClick}
      className={
        'relative font-bold cursor-pointer transition ' +
        sizeLookup[size] +
        ' ' +
        (marginless ? '' : sizeMargins[size]) +
        ' ' +
        styleColorLookup[framing][color] +
        ' ' +
        groupedLookup[grouped] +
        (full ? ' ' + blockClass : ' ' + inlineClass) +
        (disabled || props.loading ? ' ' + disabledClass : '') +
        (props.className ? ' ' + props.className : '')
      }
    >
      {props.loading ? (
        <div className="absolute inset-0 flex items-center justify-center">
          <ActivityIndicator size="small" />
        </div>
      ) : null}
      <span className={
        'flex justify-center items-center' +
        (props.loading ? ' opacity-0' : '')
      }>{props.children}</span>
    </button>
  );
};

export default Button;
