import React from 'react';

import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  // faPhone as PhoneIcon,
  faEnvelope as EnvelopeIcon,
  faHome as HomeIcon,
} from '@fortawesome/free-solid-svg-icons';
import {
  faFacebookSquare as FacebookIcon,
  faInstagram as InstagramIcon,
  faTwitterSquare as TwitterIcon,
  faLinkedin as LinkedInIcon,
  faDiscord as DiscordIcon,
} from '@fortawesome/free-brands-svg-icons';

import Type from '../Type';
import SignupButton from '../SignupButton';
import LaunchButton from '../LaunchButton';

import logo from '../../assets/images/logo.svg';

// feature flags
const showBrandIcons = false;

const linkClass = 'inline-block mr-4 sm:mr-0 sm:block';
const brandClass =
  'inline-block text-gray-500 hover:text-blue-500 w-1/5 text-3xl text-center';
const colClass = 'w-full sm:w-1/3 lg:w-1/4 px-4 border-box';
const getStartedClass = colClass + ' flex flex-col';

export function Footer() {
  return (
    <footer className="bg-gray-100 w-full p-4 pb-1 border-box">
      <div className="container m-auto break-words">
        <div className="flex flex-wrap justify-center items-start">
          <div className="hidden w-full lg:w-1/4 lg:flex lg:justify-center border-box">
            <img className="size-20 object-contain" src={logo} alt="MeshOS Logo" />
            {showBrandIcons ? <div className="text-center">
              <div className={brandClass}>
                <FontAwesomeIcon icon={FacebookIcon} />
              </div>
              <div className={brandClass}>
                <FontAwesomeIcon icon={InstagramIcon} />
              </div>
              <div className={brandClass}>
                <FontAwesomeIcon icon={TwitterIcon} />
              </div>
              <div className={brandClass}>
                <FontAwesomeIcon icon={LinkedInIcon} />
              </div>
            </div> : null}
          </div>
          <nav className={colClass}>
            <Type size="subheading">Navigate</Type>
            <div>
              <Link to="/">
                <Type className={linkClass} link>Home</Type>
              </Link>
              <Link to="/account">
                <Type className={linkClass} link>My Account</Type>
              </Link>
              <Link to="/download">
                <Type className={linkClass} link>Download</Type>
              </Link>
            </div>
          </nav>
          <div className={colClass}>
            <Type size="subheading">Say Hi</Type>
            <div>
              <div>
                <FontAwesomeIcon className="text-blue-500 w-5" icon={DiscordIcon} />
                <Link className="pl-2" to="/discord">
                  <Type inline link>Discord</Type>
                </Link>
              </div>
              <div>
                <FontAwesomeIcon className="text-blue-500 w-5" icon={EnvelopeIcon} />
                <Type className='pl-2' inline>team@meshos.com</Type>
              </div>
              {/* <div>
                <FontAwesomeIcon className="text-blue-500 w-5" icon={PhoneIcon} />
                <Type className='pl-2' inline>(415) 336-4935</Type>
              </div> */}
              <div>
                <FontAwesomeIcon className="text-blue-500 w-5" icon={HomeIcon} />
                <Type className='pl-2' inline>San Francisco, CA</Type>
              </div>
            </div>
          </div>
          <div className={getStartedClass}>
            <Type size="subheading">Get Started</Type>
            <SignupButton size='small' color="secondary" />
            <LaunchButton size='small' color="secondary" />
          </div>
        </div>
        <p className="font-sans text-sm leading-normal italic m-0 pt-1 text-center opacity-50">
          Copyright © 2024 MeshOS Inc.
        </p>
      </div>
    </footer>
  );
}

export default Footer;
