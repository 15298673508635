import { createCheckers, CheckerT } from 'ts-interface-checker';

import databaseShallowTI from '../constants/database-shallow-ti';
import {
  UserShallow as UserShallowType,
  WorkspaceShallow as WorkspaceShallowType,
  CategoryShallow as CategoryShallowType,
  ReviewShallow as ReviewShallowType,
  TeamShallow as TeamShallowType,
} from '../constants/database-shallow';
import databaseTI from '../constants/database-ti';
import {
  User as UserType,
  Workspace as WorkspaceType,
  Category as CategoryType,
  Featured as FeaturedType,
  Review as ReviewType,
  Team as TeamType,
} from '../constants/database';

// shallow type checkers (see https://github.com/gristlabs/ts-interface-checker)
export const {
  UserShallow,
  WorkspaceShallow,
  CategoryShallow,
  ReviewShallow,
  TeamShallow
} = createCheckers(databaseShallowTI) as {
  UserShallow: CheckerT<UserShallowType>,
  WorkspaceShallow: CheckerT<WorkspaceShallowType>,
  CategoryShallow: CheckerT<CategoryShallowType>,
  ReviewShallow: CheckerT<ReviewShallowType>,
  TeamShallow: CheckerT<TeamShallowType>,
};

// full type checkers (see https://github.com/gristlabs/ts-interface-checker)
export const {
  User,
  Workspace,
  Category,
  Featured,
  Review,
  Team
} = createCheckers(databaseTI, databaseShallowTI) as {
  User: CheckerT<UserType>,
  Workspace: CheckerT<WorkspaceType>,
  Category: CheckerT<CategoryType>,
  Featured: CheckerT<FeaturedType>,
  Review: CheckerT<ReviewType>,
  Team: CheckerT<TeamType>,
};

// TODO: Move back to helpers?
