import React from 'react';

import propOrDefault from '../../utils/propOrDefault';
import { openerURL } from '../../constants/urls';

import Button from '../Button';

import logo from '../../assets/images/logo.svg';

export interface LaunchButtonProps {
  size?: 'large' | 'medium' | 'small';
  color?: 'primary' | 'secondary' | 'light' | 'dark';
  className?: string;
}

export function LaunchButton(props: LaunchButtonProps) {
  const size = propOrDefault(props.size, 'medium');
  const color = propOrDefault(props.color, 'primary');

  let iconClass = "flex-none";
  if (size === 'small') {
    iconClass += " w-6 h-6";
  } else if (size === 'large') {
    iconClass += " h-10 w-10";
  } else {
    iconClass += " h-8 w-8";
  }

  const openMesh = () => {
    window.open(openerURL + '/', '_blank');
  };

  return (
    <Button
      onClick={openMesh}
      color={color}
      size={size}
      full={size === 'large'}
      className={props.className}
    >
      <span className='flex-1'>Launch MeshOS &nbsp;</span>
      <img className={iconClass} src={logo} alt="MeshOS Logo" />
    </Button>
  );
}

export default LaunchButton;
