import React from 'react';

import { Link } from 'react-router-dom';

import { useAuthProfile } from '../../api';

import SignupButton from '../SignupButton';

import logo from '../../assets/images/headerLogo.svg';
import headerBG from '../../assets/images/headerBG.svg';


export interface HeaderProps {
  name?: string;
}

export const Header: React.FC<HeaderProps> = (_props) => {
  const [me] = useAuthProfile();

  const menuItemClass = 'px-1 sm:px-4 py-1';
  const menuLinkClass = 'text-white hover:underline';
  return (
    <header
      className="flex items-stretch justify-stretch md:justify-between flex-wrap md:sticky md:top-0 shadow-md z-50 relative bg-cover overflow-hidden"
      style={{ backgroundImage: 'url(' + headerBG + ')' }}
    >
      <div className="hidden md:flex md:items-center md:justify-center md:flex-shrink-0 text-white mr-0 md:mr-3 w-full md:w-auto bg-white md:bg-transparent overflow-hidden md:overflow-visible">
        <Link to="/">
          <img
            style={{ height: '4.7rem' }}
            alt="MeshOS"
            className="grow-lg fill-current m-0 -my-1 -mr-2 md:mr-0"
            src={logo}
          />
        </Link>
      </div>
      <nav className="p-3 pb-6 md:pb-3 -m-3 mt-3 md:m-0 bg-blue-800 md:bg-transparent w-full flex-grow flex justify-center md:justify-between flex-wrap md:items-center md:w-auto">
        <ul className='list-none flex gap-1 text-lg p-0 m-0'>
          <li className={menuItemClass}>
            {me
              ? <Link className={menuLinkClass} to="/account">My Account</Link>
              : <Link className={menuLinkClass} to="/login">Login</Link>}
          </li>
          <li className={menuItemClass}>
            <Link className={menuLinkClass} to="/discord">Community</Link>
          </li>
          <li className={menuItemClass}>
            <Link className={menuLinkClass} to="/download">Download</Link>
          </li>
        </ul>
        <div className="flex">
          <SignupButton color="light" size="small" />
        </div>
      </nav>
    </header>
  );
};

export default Header;
