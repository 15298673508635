import React from 'react';

import propOrDefault from '../../utils/propOrDefault';
import { sizeLookup } from '../../constants/inputLookups';

import {
  faCheck as CheckIcon,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Field } from 'formik';

export interface InputCheckboxProps {
  text?: string;
  size?: 'small' | 'medium' | 'large';
  name?: string;
  inline?: boolean;
  disabled?: boolean;
  field?: boolean;
  defaultchecked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setFieldValue?: any;
  icon?: IconDefinition;
}

export const InputCheckbox: React.FC<InputCheckboxProps> = (props) => {
  const size = propOrDefault(props.size, 'medium');
  const inline = propOrDefault(props.inline, false);
  const disabled = propOrDefault(props.disabled, false);
  const defaultChecked = propOrDefault(props.defaultchecked, false);
  const icon = propOrDefault(props.icon, CheckIcon);

  const fieldClass =
    'm-6 ' +
    sizeLookup[size] +
    (inline ? '' : ' w-full') +
    (disabled ? ' bg-gray-300 cursor-not-allowed' : '');

  return (
    <div
      className={
        // from pretty-checkbox library https://lokesh-coder.github.io/pretty-checkbox/
        'pretty p-icon p-pulse' +
        (inline ? ' inline-block' : '') +
        ' min-w-[20px] min-h-[20px]'
      }
      style={{ margin: '5pt 2pt' }}
    >
      {props.field && props.name ? (
        <Field
          type="checkbox"
          className={fieldClass}
          name={props.name}
          {...(props.onChange
            ? {
              onChange: (e: any) => {
                if (props.setFieldValue) {
                  props.setFieldValue(props.name, e.target.value);
                }
                if (props.onChange) {
                  props.onChange(e.target.value);
                }
              },
            }
            : undefined)}
        />
      ) : (
        <input
          className={fieldClass}
          type="checkbox"
          disabled={disabled}
          {...(defaultChecked ? { checked: true } : undefined)}
          {...(props.onChange ? { onChange: props.onChange } : undefined)}
        />
      )}
      <div className="state p-primary min-w-[20px] min-h-[20px]">
        <FontAwesomeIcon
          icon={icon}
          className='icon'
        />
        <label className='ml-2'>{props.text}</label>
      </div>
    </div>
  );
};

export default InputCheckbox;
