import {
  UserShallow,
  WorkspaceShallow,
  CategoryShallow,
  ReviewShallow,
  TeamShallow,
} from './database-shallow';

export const dbRoot = '/plan_c/';

export const bioChars = 400;
export const descChars = 400;
export const nameChars = 64;
export const slugChars = 64;
export const tagChars = 16;
export const tagsLimit = 5;

export type User = UserShallow & {
  // reviews?: ReviewShallow[];
  publisherProfile?: {
    // team?: TeamShallow;
    spaces?: WorkspaceShallow[];
    color?: string;
  };
};

// used in various Storybook stories for testing
export function randomUser(): User {
  const num = Math.floor(Math.random() * 100) + 1;
  let img;
  if (num > 50) {
    img = 'https://randomuser.me/api/portraits/women/' + (num - 50) + '.jpg';
  } else {
    img = 'https://randomuser.me/api/portraits/men/' + num + '.jpg';
  }
  return {
    image: img,
    displayName: 'name person',
    id: `uuid-${num}`,
    initials: 'WW',
    jsonType: '',
    userSince: 10,
    color: '#38B2AC',
  };
}

export type Workspace = WorkspaceShallow & {
  publisher: UserShallow;
  featured?: string[];
  // usage: {
  //   users?: UserShallow[];
  //   reviews?: ReviewShallow[];
  // };
};

export type Category = CategoryShallow & {
  // curator: UserShallow;
  // pastCurators: UserShallow[];
  spaces?: Workspace[];
  relatedCategories?: CategoryShallow[];
  subtitle: string;
  banner: string;
};

export type Featured = {
  categories: CategoryShallow[];
  spaces: Workspace[];
};

// UNUSED IN V1
export type Review = ReviewShallow & {
  author: UserShallow;
  product: WorkspaceShallow;
};

// UNUSED IN V1
export type Team = TeamShallow & {
  members: UserShallow[];
  spaces?: WorkspaceShallow[];
};
