import fb, { db, functions, auth } from './firebase';
import { FirebaseError } from 'firebase/app';
import { httpsCallable } from 'firebase/functions';
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  updateProfile,
} from 'firebase/auth';
import {
  get as db_get,
  update as db_update,
  remove as db_remove,
  set as db_set,
  ref as db_ref,
  query as db_query,
  orderByKey,
  limitToLast,
} from 'firebase/database';
import {
  getStorage,
  ref as storage_ref,
  uploadString,
  getDownloadURL,
} from 'firebase/storage';
import {
  dbRoot,
  User,
  Workspace,
  Category,
  Featured,
} from '../constants/database';
import { CategoryShallow } from '../constants/database-shallow';
import {
  Category as CategoryChecker,
  User as UserChecker,
  Workspace as WorkspaceChecker,
} from './checkers';
import { fullURL } from '../constants/urls';
import {
  encodeUrl as base64encode,
  decode as base64decode,
} from "@borderless/base64";

export type JsonScalar = string | number | boolean | null;
export type JsonArray = string[] | number[] | boolean[] | Record<string, JsonScalar | JsonArray>[];
export type JsonObj = { [key: string]: JsonScalar | JsonArray | JsonObj };
export type FlatJsonObj = Record<string, JsonScalar | JsonArray>;

// type guards
export function isObject(input: unknown): input is Record<string, unknown> {
  return typeof input === 'object' && input !== null && !Array.isArray(input);
}

export function stringFlattenKeys(object: JsonObj): FlatJsonObj {
  for (const key in object) {
    const child = object[key];
    if (isObject(child)) {
      object[key] = stringFlattenKeys(child);
      for (const subkey in child) {
        object[key + '/' + subkey] = child[subkey];
      }
      delete object[key];
    }
  }
  return object as Record<string, string>;
}

function restoreFirebaseConstants(object: Record<string, unknown>) {
  for (const key in object) {
    const chunks = key.split('/');
    const lastChunk = chunks.pop();
    if (lastChunk?.startsWith('.')) {
      const newKey = chunks.join('/');
      object[newKey] = { [lastChunk]: object[key] };
      delete object[key];
    }
  }
  return object;
}

function getWeekStart() {
  const today = new Date();
  const d = new Date(today.setDate(today.getDate() - today.getDay()));
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('');
}

export function strToBase64(input: string): string {
  const bytes = new TextEncoder().encode(input);
  return base64encode(bytes);
}

export function base64ToStr(input: string): string {
  const bytes = base64decode(input);
  return new TextDecoder().decode(bytes);
}

export async function fetchRefOnce(path: string): Promise<JsonObj|JsonArray|JsonScalar> {
  const ref = db_ref(db, dbRoot + path);
  const snap = await db_get(ref);
  return snap.val() as JsonObj|JsonArray|JsonScalar;
}

export async function writeToRef(path: string, data: JsonObj, fbConstants?: boolean): Promise<void> {
  const ref = db_ref(db, dbRoot + path);
  const values = fbConstants
    ? restoreFirebaseConstants(stringFlattenKeys(data))
    : stringFlattenKeys(data);
  if (isObject(values)) {
    await db_update(ref, values);
  }
}

export async function uploadDataURLToRef(path: string, dataURL: string): Promise<void> {
  const storage = getStorage(fb);
  const ref = storage_ref(storage, path);
  await uploadString(ref, dataURL, 'base64');
}

export async function firebaseIdToMeshId(fbId: string): Promise<string> {
  return await fetchRefOnce('logins/' + fbId) as string;
}

export async function publisherSlugToMeshId(slug: string): Promise<string> {
  return await fetchRefOnce(
    'marketplace_urls/publishers/' + slug.toLowerCase()
  ) as string;
}

export async function productSlugToMeshId(slug: string): Promise<string> {
  return await fetchRefOnce(
    'marketplace_urls/products/' + slug.toLowerCase()
  ) as string;
}

export async function categorySlugToCategory(slug: string): Promise<Category> {
  const category = await fetchRefOnce('marketplace_categories/' + slug.toLowerCase());
  if (CategoryChecker.test(category)) {
    category.slug = slug.toLowerCase();
    if (category.spaces) {
      category.spaces = Object.values(category.spaces).filter(
        (space) => space.approved && !space.unlisted
      );
    }
  } else {
    throw new Error('Invalid category.');
  }
  return category;
}

export async function meshIdToPublicProfile(meshId: string): Promise<User> {
  if (!meshId) {
    throw new Error('missing or empty meshId parameter');
  }
  const profile = await fetchRefOnce('users_public/' + meshId);
  if (UserChecker.test(profile)) {
    profile.id = meshId;
    return profile;
  }
  throw new Error('Invalid user object.');
}

export async function meshIdToProduct(meshId: string): Promise<Workspace> {
  const product = await fetchRefOnce('spaces_public/' + meshId);
  if (WorkspaceChecker.test(product)) {
    product.id = meshId;
    return product;
  }
  throw new Error('Invalid product.');
}

export async function meshIdToUnpublishedWorkspaceName(meshId: string): Promise<string> {
  const space = await fetchRefOnce('spaces/' + meshId);
  if (isObject(space)) {
    if (space.elements) {
      const elements = space.elements as Record<string, Record<string, string>>;
      if (elements[meshId]) {
        return elements[meshId].name;
      }
    }
  }
  throw new Error('Invalid unpublished workspace');
}

export async function loadImageFromRef(path: string): Promise<string> {
  const storage = getStorage(fb);
  const ref = storage_ref(storage, path);
  return await getDownloadURL(ref);
}

export async function isPublisherSlugTaken(slug: string): Promise<boolean> {
  if (slug === '') { return true; }
  const result = await fetchRefOnce('marketplace_urls/publishers/' + slug.toLowerCase());
  return result !== null;
}

export async function isProductSlugTaken(slug: string): Promise<boolean> {
  if (slug === '') { return true; }
  const result = await fetchRefOnce('marketplace_urls/products/' + slug.toLowerCase());
  return result !== null;
}

export async function getCurrentlyFeatured(week?: string): Promise<Featured> {
  let ref = db_ref(db, dbRoot + 'marketplace_featured/' + (week ?? getWeekStart()));
  try {

    const snap = await db_get(ref);
    const featured = snap.val() as Featured;
    featured.spaces = Object.values(featured.spaces).filter(
      (space) => !space.unlisted
    );
    return featured;

  } catch (_err) {
    // couldn't get a feature for this week, try to figure it out manually
    ref = db_ref(db, dbRoot + 'marketplace_featured/');
    const query = db_query(ref, orderByKey(), limitToLast(1));
    const snap = await db_get(query);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const featured = Object.values(snap.val())[0] as Featured;
    featured.spaces = Object.values(featured.spaces).filter(
      (space) => !space.unlisted
    );
    return featured;
  }
}

export async function getAllCategories(): Promise<CategoryShallow[]> {
  const results = await fetchRefOnce('marketplace_categories_minimal') as unknown as Record<string, CategoryShallow>;
  return Object.values(results);
}

export enum CreateUserErrorActionCode {
  WeakPassword,
  AccountAlreadyExists,
  LoginRequired,
}

export class CreateUserError extends Error {
  name: string;
  code: CreateUserErrorActionCode;

  constructor(code: CreateUserErrorActionCode, message: string) {
    super(message);
    this.name = "CreateUserError";
    this.code = code;
  }
}

// triggers a new verification email from Firebase, requires a logged in user
export async function sendVerifyEmail(): Promise<void> {
  if (auth.currentUser != null) {
    //console.log(`sending verify email`);

    // the generated email has a link to Firebase, but we can specify a redirect back to
    // our site, see https://firebase.google.com/docs/auth/web/passing-state-in-email-actions
    const redirectUrl = fullURL + "/verifyemail";
    return await sendEmailVerification(auth.currentUser, {url: redirectUrl});
  }
  // "auth/requires-recent-login"
  // "auth/expired-action-code"
  throw new CreateUserError(
    CreateUserErrorActionCode.LoginRequired,
    "You must login before you can verify your email address"
  );
}

// IMPORTANT: this interface must match the type in the cloud function exactly
export interface NewUserRecord {
  firstname: string;
  lastname: string;
  email: string;
  referralCode?: string;
}

// marker we leave in the database to pick up post email verification
export interface UserSignup {
  firstName: string;
  lastName: string;
  date: string;
  referredByCode?: string;
}

export async function loadCreateUserMarker(): Promise<UserSignup> {
  // must be logged in
  if (auth.currentUser == null) {
    throw new CreateUserError(
      CreateUserErrorActionCode.LoginRequired,
      "You must login before you can finish creating your account"
    );
  }

  const ref = db_ref(db, dbRoot + `users_signup/${auth.currentUser.uid}`);
  const snap = await db_get(ref);
  const marker = snap.val() as UserSignup;
  return marker;
}

export async function deleteCreateUserMarker(): Promise<void> {
  // must be logged in
  if (auth.currentUser == null) {
    throw new CreateUserError(
      CreateUserErrorActionCode.LoginRequired,
      "You must login before you can finish creating your account"
    );
  }

  const ref = db_ref(db, dbRoot + `users_signup/${auth.currentUser.uid}`);
  await db_remove(ref);
}

// creates a new Firebase user, errors come directly from Firebase SDK
export async function createFirebaseUser(user: NewUserRecord, password: string): Promise<string> {
  try {

    // note: when successful, this also logs the user in
    const fbUser = await createUserWithEmailAndPassword(auth, user.email, password);
    const uid = fbUser.user.uid;

    console.log(`firebase user created: ${uid}`);

    // might as well assign the display name to the Firebase profile
    await updateProfile(
      auth.currentUser!,
      { displayName: user.firstname + ' ' + user.lastname }
    );

    console.log('firebase account updated');

    // leave a marker that we will pick up post email verification
    const marker: UserSignup = {
      firstName: user.firstname,
      lastName: user.lastname,
      date: new Date().toISOString(),
    };
    if (user.referralCode) {
      marker.referredByCode = user.referralCode;
    }
    const ref = db_ref(db, dbRoot + `users_signup/${uid}`);
    await db_set(ref, marker);

    console.log('marker saved');

    return uid;

  } catch (e) {
    // handle various Firebase errors
    if (e instanceof FirebaseError) {
      switch(e.code) {
      case "auth/weak-password":
        //console.log('weak password');
        throw new CreateUserError(
          CreateUserErrorActionCode.WeakPassword,
          "The password you selected is too weak, try again"
        );


      case "auth/credential-already-in-use":
      case "auth/email-already-in-use":
      case "auth/account-exists-with-different-credential":
        //console.log('account already exists');
        throw new CreateUserError(
          CreateUserErrorActionCode.AccountAlreadyExists,
          "That email address is already in use"
        );
      }
      console.warn("unhandled FirebaseError:", e);
    } else {
      console.warn("unexpected error received:", e);
    }
    throw e; // something unexpected
  }
}

// IMPORTANT: this interface must match the cloud function exactly
export interface SelfSignupNewUserRecord extends NewUserRecord {
  firebaseUid: string;
}

// IMPORTANT: this interface must match the cloud function exactly
export interface CreateUserResponse {
  status: string;
  uid: string;
  meshId: string;
  warnings: string[];
}

// wraps the "userSignUp" callable cloud function
export async function createMeshUser(): Promise<CreateUserResponse> {

  // uncomment for UI testing
  // return new Promise<CreateUserResponse>((resolve, reject) => {
  //   setTimeout(() => {
  //     const resp: CreateUserResponse = {
  //       status: "SUCCESS",
  //       uid: "abc123",
  //       meshId: "83222EF8-5B2F-45EF-AAD2-5E6932AEA34A",
  //       warnings: [],
  //     };
  //     resolve(resp);
  //     //reject(new Error('could not create firebase user: password too short'));
  //   }, 5000);
  // });

  // must be logged in
  if (auth.currentUser == null) {
    throw new CreateUserError(
      CreateUserErrorActionCode.LoginRequired,
      "You must login before you can finish creating your account"
    );
  }

  // look up the marker we left before email verification
  const marker = await loadCreateUserMarker();

  if (!marker) {
    throw new Error("unable to load UserSignup marker");
  }

  // prepare the new user details
  const user: SelfSignupNewUserRecord = {
    firstname: marker.firstName,
    lastname: marker.lastName,
    email: auth.currentUser.email!,
    firebaseUid: auth.currentUser.uid,
  };

  if (marker.referredByCode) {
    user.referralCode = marker.referredByCode;
  }

  // invoke the cloud function
  const userSignUp = httpsCallable<SelfSignupNewUserRecord,CreateUserResponse>(functions, 'userSignUp');
  const response = await userSignUp(user);

  // delete the marker record
  await deleteCreateUserMarker();

  return response.data;
}
